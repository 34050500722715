@import "../../styles/colors";
.searchResultsWrap {
  position: relative;
  padding: 76px 0 70px;
  min-height: calc(100vh - 430px);
  @media (max-width: 991px) {
    padding: 50px 0;
  }
  @media (max-width: 767px) {
    padding: 40px 0;
  }

  .searchWord {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 30px;
    span {
      color: $pmColor;
      font-weight: 600;
    }
    @media (max-width: 767px) {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  .searchResultsListing {
    position: relative;
    .searchResultList {
      cursor: pointer;
      position: relative;
      padding: 40px 0;
      border-bottom: 1px solid #e1e1e1;
      display: flex;
      &:nth-last-of-type(1) {
        border-bottom: 0;
      }
      @media (max-width: 991px) {
        padding: 30px 0;
      }
      @media (max-width: 767px) {
        padding: 25px 0;
      }
      @media (max-width: 576px) {
        padding: 20px 0;
      }
      .searchThumb {
        flex: 0 0 180px;
        max-width: 180px;
        width: 100%;
        margin-bottom: 0;
        @media (max-width: 767px) {
          flex: 0 0 130px;
          max-width: 130px;
        }
        @media (max-width: 576px) {
          flex: 0 0 120px;
          max-width: 120px;
          margin-bottom: 0;
        }
        img {
          width: 100%;
          height: 100px;
          object-fit: cover;
          @media (max-width: 767px) {
            height: 85px;
          }
          @media (max-width: 576px) {
            height: 67px;
          }
        }
      }
      .searchContent {
        flex: 1;
        padding-left: 20px;
        @media (max-width: 767px) {
          padding-left: 15px;
        }
        @media (max-width: 576px) {
          padding-left: 12px;
        }
      }
      .category {
        font-size: 16px;
        line-height: 17px;
        font-weight: 500;
        display: block;
        margin-bottom: 7px;
        color: $pmColor;
        @media (max-width: 767px) {
          font-size: 14px;
        }
        @media (max-width: 576px) {
          top: 20px;
          font-size: 12px;
          line-height: 12px;
          margin-bottom: 5px;
        }
      }
      .date {
        position: absolute;
        top: 40px;
        right: 0;
        font-size: 13px;
        line-height: 15px;
        font-weight: normal;
        display: block;
        margin-bottom: 7px;
        color: #000;
        @media (max-width: 991px) {
          top: 30px;
        }
        @media (max-width: 767px) {
          top: 25px;
        }
        @media (max-width: 576px) {
          top: 20px;
          font-size: 11px;
          line-height: 12px;
          margin-bottom: 5px;
        }
      }
      h3 {
        font-size: 28px;
        line-height: 34px;
        font-weight: normal;
        margin-bottom: 15px;
        color: #000;
        span {
          color: $pmColor;
        }
        @media (max-width: 991px) {
          font-size: 24px;
          line-height: 30px;
        }
        @media (max-width: 767px) {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 10px;
        }
        @media (max-width: 576px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
      article {
        font-size: 18px;
        line-height: 30px;
        font-weight: normal;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        p {
          font-size: 18px;
          line-height: 30px;
          font-weight: normal;
        }
        @media (max-width: 991px) {
          font-size: 17px;
          line-height: 28px;
        }
        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 1.88;
          p {
            font-size: 16px;
            line-height: 1.88;
          }
        }
        @media (max-width: 576px) {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
    :global {
      .btn-loadmore {
        max-width: 250px;
        width: 100%;
        height: 61px;
        margin: 46px auto 0;
        padding: 7px 45px 6px 50px;
        border-radius: 5px;
        background-color: #320150;
        font-size: 22px;
        font-weight: 400;
        line-height: 2.18;
        letter-spacing: 1.2px;
        text-align: center;
        color: #ffffff;
        outline: none;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .spinner-grow {
          margin-left: 7px;
        }
        @media (max-width: 991px) {
          font-size: 18px;
          height: 50px;
          line-height: 50px;
          padding: 0px 15px;
        }
        @media (max-width: 767px) {
          max-width: 155px;
          height: 45px;
          line-height: 45px;
          text-align: center;
          margin-top: 30px;
          font-size: 14px;
        }
      }
    }
  }
}
