@import '../../styles/_colors.scss';
@import '../../styles/_animation.scss';


:global .scroll-disable{
    overflow:hidden !important;
    padding-right: 17px;
}

.EpisodeCarousel{
    background-color: #28013F;
    cursor: pointer;
    &#episodeCarousel{
        position:relative;
         background-color: red;
    }

    @media only screen  and (max-width: 1466px){
        // max-height: 550px;
        // min-height: 550px;

        :global .carousel{
            // max-height: 552px;
            // min-height: 552px;
            .carousel__slide{
                margin-bottom: 15px;
            }
            .carousel__slider{
                padding-top: 25px;
                min-height: 38vw;
                .carousel__slider-tray-wrapper{
                    min-height: 36vw;
                    padding-bottom: 100%!important;
                }
            }
            
        }
    
    }

    @media only screen  and (max-width: 1366px){
        :global .carousel__slider{
            padding-top: 20px;
            min-height: 82vh;
            .carousel__slider-tray-wrapper{
                .carousel__slide {
                    margin-bottom: 15px;
                }
            }
        }
    }

    :global .carousel__slider{
        padding-top: 30px;

    }

    .active{
        background-color: #fff;
    }
    button.nexButton,button.preButton{
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
    }
    .nexButton,.preButton{
        left: 50%;
        border: 0;
        background: transparent;
        color: #fff;
        font-size: 40px;
        line-height: 0;
        z-index: 1;
        position: absolute;
        margin: auto;
        transform: translateX(-50%);
        height: 30px;
        overflow: hidden;
        svg{
            position: relative; 
        }
        button{
            height: 40px;
            background-color: transparent;
            color: #ffff;
            box-shadow: none;
            border: 0;
            margin: 0 auto;
        }
        &:focus{
            outline: none;
        }
        &::after,&::before{
            content: "";
            position: absolute;
            top: 0;
            width: 100vh;
            height: 100%;
            background-color: #28013f;
            z-index: -1;
            display: none;
        }
        &::before{
            left: 0;
        }
        &::after{
            right: 0;
        }
        &:hover{
                -webkit-animation-name: hvr-wobble-vertical;
                animation-name: hvr-wobble-vertical;
                -webkit-animation-duration: 1s;
                animation-duration: 1s;
                -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
                -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
        }
        
    }

    .nexButton{
        bottom: 0;
        
        button{
            position: relative ;
            bottom: 5px;
        }
        svg{
            bottom: 9px;
        }
        
        
    }
    .preButton{
        top: 0;
        button{
            // padding: 11px 3px 3px;
        }
        svg{
            top: 0;
        }
    }

    .imageItem{
        padding: 5px;
        position: relative;
        
        img{
            width: 100%;
            
            overflow: hidden;
            object-fit: cover;
            @media only screen  and (max-width: 767px){
                max-height: 84px;
            }
        }
        .episode{
            position: absolute;
            background-color: #fff;
            right: 20px;
            top: 20px;
            border-radius: 2px;
            font-weight: 500;
            font-size: 12px;
            padding: 5px;
        }
        .play{
            position: absolute;
            bottom: 15px;
            right: 15px;
            color: #fff;
            font-size: 20px;
            background: #320150;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            padding: 2px;
            text-align: center;
            svg{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 15px;
            }
        }
        &:hover{
            .play{
                -webkit-animation-name: hvr-buzz-out;
                animation-name: hvr-buzz-out;
                -webkit-animation-duration: 0.75s;
                animation-duration: 0.75s;
                -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
                -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
            }
        }
    }
    overflow: hidden;
}

@media screen and (max-width:1466px) {
    .EpisodeCarousel{
        .imageItem{
            .episode{
                font-size: 10px;
                right: inherit;
                bottom: 20px;
                left: 20px;
                top:inherit;
            }
            .play{
                font-size: 12px;
                height: 28px;
                width: 28px;
                line-height: 22px;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    :global .scroll-disable{
        overflow:hidden !important;
        padding-right: 0;
    }
    .EpisodeCarousel{
        display: flex;
        flex-wrap: wrap;
        height: auto;

        // :global .carousel__slider{
        //     padding-top: 0;
        //     padding-left: 40px;
        // }{
        :global .carousel{
            .carousel__slider-tray{
                margin: 0;
            }
            .carousel__slide{
                margin-bottom: 0 !important;
                img {
                    min-height: 84px;
                }
            }
            .carousel__slider{
                padding-top: 25px;
                padding-left: 0;
                min-height: auto;
                .carousel__slider-tray-wrapper{
                    min-height: auto ;
                    padding-bottom: 35px !important;
                }
            }
            
        }
        .imageItem{
            flex: 100%;
            max-width:100%;
            .play{
                font-size: 11px;
                height: 24px;
                width: 24px;
                line-height: 18px;
            }
        }
        ul{
            display: flex;
            margin-top: 20px;
            width: 100%;
            li{
                height: 110px;
                flex: 0 0 160px;
                max-width: 160px !important;
                width: 160px !important;
                padding-bottom: 0 !important;
                
            }
        }
        .nexButton{
            bottom: 0;
            top: 0;
            right: -9px;
            left: inherit;
            transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
}
        .preButton{
            top: 0;
            bottom: 0;
            right: inherit;
            left: -9px;
            transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
}
        .nexButton,.preButton{
            height: 35px;
            top: inherit;
            bottom: 3px;
            button{
                height: 35px;
                padding: 0;
            }
        }
        .nexButton{
            left: 50%;
            right: inherit;
            
            svg{
                bottom: 12px;
            }
            &::after,&::before{
                top:0;
            }
        }
        .preButton{
            right: 50%;
            left: inherit;
            svg{
                top: 6px;
            }
            &::after,&::before{
                top:0;
            }
        }
    }
}


@media screen and (max-width: 400px) {
    .EpisodeCarousel{
        ul{
            li{
                height: 110px;
                flex: 0 0 140px;
                max-width: 140px !important;
                width: 160px!important;
                padding-bottom: 0 !important;
                
            }
        }
        .imageItem{
            .episode{
                bottom: 12px;
            }
            .play{
               bottom: 12px;
            }
        }
    }
}
//@media screen and (max-width: 575px) {
    // .EpisodeCarousel{
    //     .imageItem{
    //         flex: 0 0 50%;
    //         max-width: 50%;
    //     }
    // }
//}
// .EpisodeCarousel {
//     padding: 28px 0;
//     background-color: $fbackgroundColor;
//     .episodeImg {
//         max-width: 189px;
//         padding: 6px 8px 5px 7px;
//         margin-left: -1px;
//         height: 132px;
//         object-fit: cover;
//         cursor: pointer;
//     }
//     .EpisodeCarouselWrap{

//         .carouselBtn {
//             .indexnumber{
//                 background: #fff;
//                 position: absolute;
//                 height: 20px;
//                 width: 20px;
//                 color: #000;
//                 text-align: center;
//                 padding-top: 2px;
//                 right: 10px;
//                 top: 15px;
//                 font-size: 12px;
//                 border-radius: 3px;
//                 user-select: none !important;
//             }
//             .playbutton{
//                 width: 25px;
//                 position: absolute;
//                 right: 15px;
//                 background: #000;
//                 height: 25px;
//                 bottom: 20px;
//                 border-radius: 50%;
//                 cursor: pointer;
//                 img{
//                     width: 35%;
//                     display: block;
//                     margin: auto;
//                     margin-top: 7px;
//                 }
//                 // .activeVideo{
//                 //     background: chartreuse !important;
//                 //     height: 178px !important;
//                 // }

//             }

//         }


//         :global .react-multi-carousel-list{
//             position: unset !important;
//             .react-multiple-carousel__arrow--right {
//                 right: calc(12% + 1px);
//             }
//             .react-multiple-carousel__arrow--left {
//                 left: calc(12% + 1px);
//             }
//             .react-multiple-carousel__arrow {
//                 background-color: white;
//                 outline: none;
//             }
//             .react-multiple-carousel__arrow::before {
//                 color: #320150 !important;
//                 font-weight: bold;
//             }

//         }
        
//     }


//     .activeVideo{
//         // background: chartreuse !important
//         background: white;
//     }
//     .pause{
//         border-width: 0px 0px 0px 37px;
//         border-color: #202020;
//     }
// }

// @media screen and (max-width: 1440px) {
   
//         .EpisodeCarouselWrap{

//             :global .react-multi-carousel-list{
//                 position: unset !important;
//                 .react-multiple-carousel__arrow--right {
//                     right: calc(8% + 1px) !important;
//                 }
//                 .react-multiple-carousel__arrow--left {
//                     left: calc(8% + 1px) !important;
//                 }
               
    
//             }
       
       
//     }
    
// }



// @media screen and (max-width:480px) {
   


// .EpisodeCarousel {
//     .episodeImg {
//         // max-width: 168px !important;
//         // max-width: 193px !important;
//         max-width: 185px !important;
//     }
//     .EpisodeCarouselWrap{

//         :global .react-multi-carousel-list{
//             position: unset !important;
//             .react-multiple-carousel__arrow--right {
//                 right: calc(1% + 1px) !important;
//             }
//             .react-multiple-carousel__arrow--left {
//                 left: calc(1% + 1px) !important;
//             }
           

//         }
   
   
// }
// }

// }