@import '../../styles/colors';

@function getColor($from){
   @if $from=='shimmer'{
       @return $shimmerColor
   } @else {
        @return $shimmerColor
   }
}


@function blinds($open: 0) {
    @return linear-gradient(180deg,$shimmerColor $open*100%, $shimmerColor 0);
}


@mixin Image($from,$height) {
        width: 100%;
        background: getColor($from);
        height: $height;
        margin-bottom:10px;
        background: blinds() 100%;
        animation: ease-in 2s cubic-bezier(0.4, 0, 1, 1) infinite alternate;
        border-radius: 6px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
}

@mixin ShimmerAnime($seconds) {
    background: $shimmerColor;
        background-image: linear-gradient(to right, $shimmerColor 0%, #dedede 20%, $shimmerColor 40%, $shimmerColor 100%);
        background-repeat: no-repeat;
        background-size:cover;
        display: inline-block;
        position: relative; 
        
        -webkit-animation-duration: $seconds;
        -webkit-animation-fill-mode: forwards; 
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-name: placeholderShimmer;
        -webkit-animation-timing-function: ease-in;
        animation-duration: $seconds;
        animation-fill-mode: forwards; 
        animation-iteration-count: infinite;
        animation-name: placeholderShimmer;
        animation-timing-function: ease-in;
}

.Shimmer{
    line-height:0;
    .image{
     @include Image('shimmer',360px) ; 
     @include ShimmerAnime(2s);
     margin: 0;
    }
    .content{
        background:blinds() 50%/ 5em;
        animation: ease-in 2s cubic-bezier(0.4, 0, 1, 1) infinite alternate;
        -webkit-animation:ease-in 2s cubic-bezier(0.4, 0, 1, 1) infinite alternate;
        margin-top: 25px;
        width: 80%;
        margin-bottom: 25px;
        margin-left: 15px;
        height: 20px;
        @include ShimmerAnime(2s);
    }
    border: 1px solid $shimmerColor;
    border-radius: 6px;
    margin-bottom: 27px;
}

.bannerShimmer{
    height: 450px;
    background: blinds() 50%/ 5em;
    animation: ease-in 1s cubic-bezier(0.4, 0, 1, 1) infinite alternate;
    @include ShimmerAnime(2s);
}

.episodeShimmer{
    .image{
        @include Image('shimmer',285px);
        @include ShimmerAnime(2s);
        margin: 0;
    }
    .content{
        padding: 4% 15px 5%;
        line-height:0;
        height:137px;
        .content_grid{
            display:flex;
            
            .grid_left{
                width: 100%;
                max-width:80%;
                flex:0 0 80%;

                .subtitle{
                    background:blinds() 50%/ 5em;
                    animation: ease-in 1s cubic-bezier(0.4, 0, 1, 1) infinite alternate;
                    height: 15px;
                    width: 90%;
                    @include ShimmerAnime(2s);
                }
                .maintitle{
                    //min-height: 80px;
                    width: 90%;
                    margin-top: 28px;
                    margin-bottom: 5px;
                    .title{
                        background:blinds() 50%/ 5em;
                        animation: ease-in 1s cubic-bezier(0.4, 0, 1, 1) infinite alternate;
                        height: 20px;
                        display: block !important;
                        width: 100%;
                        @include ShimmerAnime(2s);
                        &:last-child{
                            margin-top: 10px;
                            width:85%;
                        }
                    }  
                }
            }
            .grid_right{
                width: 100%;
                max-width:20%;
                flex:0 0 20%;

                 .time{
                    background:blinds() 50%/ 5em;
                    animation: ease-in 1s cubic-bezier(0.4, 0, 1, 1) infinite alternate;
                    height: 28px;
                    margin-top: 36px;
                    width: 60px;
                    margin-bottom: 15px;
                    margin-left: 10px;
                    float: right;
                    @include ShimmerAnime(2s);
                }
            }
        }
    }
    border: 1px solid $shimmerColor;
    margin-bottom: 8%;
    border-radius: 10px;

}
.EpisodeDetailShimmer{
    display: flex;
    @media (max-width:767px) {
        display: block;
    }
    .video{
        
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
        @include ShimmerAnime(2s);
        @media (max-width:767px) {
            flex: 0 0 100%;
            max-width: 100%;
            height: 57.366vw;
            width: 100%;
        }
    }
    .videoList{
        min-height: 44vw;
        padding: 45px 15px;
        position: relative;
        @media (max-width:767px) {
            display: flex;
            min-height: 170px;
            padding: 25px 0;
        }
        &:after,&::before{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            
        }
        &:before{
            top:10px;
            border-bottom: 20px solid $shimmerColor;
            @media (max-width:767px) {
                top: inherit;
                bottom: 20px;
                transform: rotate(-90deg);
                right: 45px;
            }

        }
        &:after{
            bottom:10px;
            border-top: 20px solid $shimmerColor;
            @media (max-width:767px) {
                bottom: 20px;
                transform: rotate(-90deg);
                left: 45px;
            }
        }
        
    }
    .videoBlock{
        width: 290px;
        height: 172px;
        background: blinds() 50%/ 5em;
        animation: ease-in 2s cubic-bezier(0.4, 0, 1, 1) infinite alternate;
        margin-bottom: 20px;
        @include ShimmerAnime(2s);
        &:last-child{
            margin-bottom: 0;
        }
        @media (max-width:767px) {
            flex: 0 0 150px;
            width: 150px;
            height: 84px;
            margin-right: 12px;
            margin-bottom: 0;
            &:last-child{
                margin-right: 0;
            }
        }
    }
}

.CraftShimmer{
    .image{
        @include Image('shimmer',290px);
        @include ShimmerAnime(2s);
    }
    .content{
        .title{
            background:blinds() 50%/ 5em;
            animation: ease-in 1s cubic-bezier(0.4, 0, 1, 1) infinite alternate;
            height: 30px;
            margin-top: 18px;
            width: 75%;
            margin-bottom: 15px;
            margin-left: 10px;
            @include ShimmerAnime(2s);
        }
        .description{
            span{
                display: block;
                width: 90%;
                height: 7px;
                background:blinds() 50%/ 5em;
                animation: ease-in 1s cubic-bezier(0.4, 0, 1, 1) infinite alternate;
                margin-bottom: 5px;
                @include ShimmerAnime(2s);
            }
            span:last-child(){
                width: 100%;
            }
        }
    }
}
.GalleryShimmer{
    line-height:0;
    height: 277px;
    .image{
     @include Image('shimmer',207px) ; 
     @include ShimmerAnime(2s);
     margin: 0;
    }
    .content{
        background:#fff;
        animation: ease-in 2s cubic-bezier(0.4, 0, 1, 1) infinite alternate;
        -webkit-animation:ease-in 2s cubic-bezier(0.4, 0, 1, 1) infinite alternate;
        height:66px;
        display:flex;
        justify-content: space-between;
        padding: 1.25rem;

        .flag{
            width:24px;
            height:24px;
            flex:0 0 24px;
            @include ShimmerAnime(2s);
        }
        .title{
            width:80%;
            height:24px;
            @include ShimmerAnime(2s);
        }
    }

    border: 1px solid $shimmerColor;
    border-radius: 6px;
    margin-bottom: 27px;
}
.PoliciesShimmer{
    .title{
        width:80%;
        height: 66px;
        margin-bottom:25px; 
    }
    .subtitle{
        width:70%;
        height: 26px;
        margin-bottom: 25px; 
    }
    .pTitle{
        width:45%;
        height: 24px;
        margin-bottom: 25px; 
    }
    .pContent span{
        height: 15px;
        display: block;
        width: 100%;
        margin-bottom: 15px; 
    }
    .pContent span:nth-child(2){
        width: 95%;
    }
    .pContent span:nth-child(3){
        width: 98%;
    }
    .pContent span:nth-child(4){
        width: 90%;
    }
    .pContent span:last-child{
        margin-bottom: 25px; 
    }
    .pContent span.half{
        width: 50%;
    }
    .pContent span.quater{
        width: 25%;
    }

    .title,.subtitle,.pTitle,.pContent span{
        background: blinds() 50%/ 5em;
        animation: ease-in 2s cubic-bezier(0.4, 0, 1, 1) infinite alternate;
        @include ShimmerAnime(2s);
    }
}
@-webkit-keyframes placeholderShimmer {
    0% {
        background-position: 0 0;
    }
      
    100% {
        background-position: 100vw 0; 
    }
}
@keyframes placeholderShimmer {
    0% {
      background-position: 0 0;
    }
    
    100% {
      background-position: 100vw 0; 
    }
}
   
@keyframes blinds { to { background-image: blinds(1) } }