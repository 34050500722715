html * {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

html ::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

html {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  scroll-behavior: smooth;
}

html ::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

html * {
  -ms-overflow-style: inherit;
  /* for Internet Explorer, Edge */
  scrollbar-width: 10px;
  /* for Firefox */
}

.socialMediaShare {
  position: fixed;
  top: calc(50vh - 96px);
  right: 30px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  -webkit-animation-name: fixedShare;
  animation-name: fixedShare;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.socialMediaShare .mobShareIcon {
  display: none;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.socialMediaShare .socialShareIcons {
  display: flex;
  flex-direction: column;
}

.socialMediaShare button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px !important;
  color: #fff !important;
  width: 50px;
  height: 50px;
  border: 1px solid #320150 !important;
  background-color: #320150 !important;
  border-radius: 50%;
  text-decoration: none;
  transition: all .4s ease-in-out;
  margin: 7px 0;
}

.socialMediaShare button:hover {
  color: #fff !important;
}

.socialMediaShare button.facebookIcon {
  position: relative;
}

.socialMediaShare button.facebookIcon:hover {
  background: #3b5998 !important;
  border-color: #3b5998 !important;
  color: #fff !important;
}

.socialMediaShare button.twitterIcon {
  position: relative;
}

.socialMediaShare button.twitterIcon:hover {
  background: #1da1f2 !important;
  border-color: #1da1f2 !important;
  color: #fff !important;
}

.socialMediaShare button.whatsappIcon {
  position: relative;
}

.socialMediaShare button.whatsappIcon:hover {
  background: #25d366 !important;
  border-color: #25d366 !important;
  color: #fff !important;
}

.socialMediaShare.pull-left {
  left: 12px;
  right: inherit;
}

.socialMediaShare.pull-bottom {
  top: inherit;
  bottom: 38vh;
  flex-direction: revert;
  right: 8vw;
  position: absolute;
}

.socialMediaShare.pull-bottom button {
  background-color: #fff !important;
  color: #320150 !important;
  margin: 0 7px;
}

.scroll-disable {
  overflow: hidden !important;
  position: relative;
}

.mobMenu .topMenuSm {
  z-index: 1000000 !important;
}

.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color: #FDB300 !important;
  border-color: #FDB300 !important;
  box-shadow: none !important;
}

.full_link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.form-control {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.form-control:focus {
  box-shadow: none !important;
}

@media screen and (max-width: 1460px) {
  .socialMediaShare.pull-bottom {
    bottom: 40vh;
  }
  .socialMediaShare.pull-bottom button {
    width: 44px;
    height: 44px;
  }
}

@media screen and (max-width: 1366px) {
  .socialMediaShare {
    top: 50vh;
  }
  .socialMediaShare button {
    width: 34px;
    height: 34px;
    margin: 4px 0;
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1024px) {
  .socialMediaShare {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .socialMediaShare {
    display: none !important;
    top: calc(50vh - 20px);
    left: 12px;
    right: inherit;
    width: 44px;
  }
  .socialMediaShare:hover .socialShareIcons {
    opacity: 1;
    width: auto;
    height: auto;
    pointer-events: visible;
    left: 111%;
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }
  .socialMediaShare:hover .socialShareIcons:after {
    opacity: 1;
    left: -9px;
  }
  .socialMediaShare .mobShareIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #ffb800;
    position: relative;
    border-radius: 50%;
  }
  .socialMediaShare .mobShareIcon img {
    width: 20px;
  }
  .socialMediaShare .socialShareIcons {
    position: absolute;
    background-color: #fff;
    top: -3px;
    left: 0;
    opacity: 0;
    padding: 5px;
    border-radius: 15px;
    pointer-events: none;
    display: flex;
    flex-direction: row;
    transition: all .4s ease-in-out;
    z-index: 1;
  }
  .socialMediaShare .socialShareIcons button {
    margin: 0 5px;
    width: 36px !important;
    height: 36px !important;
    font-size: 18px !important;
  }
  .socialMediaShare .socialShareIcons:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 0;
    height: 0;
    opacity: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #fff;
    transition: left .5s ease-out;
  }
  .socialMediaShare.pull-left {
    width: 44px;
  }
  .socialMediaShare.pull-left:hover .socialShareIcons {
    opacity: 1;
    width: auto;
    height: auto;
    pointer-events: visible;
    left: 111%;
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }
  .socialMediaShare.pull-left:hover .socialShareIcons:after {
    opacity: 1;
    left: -9px;
  }
  .socialMediaShare.pull-left .mobShareIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #ffb800;
    position: relative;
    border-radius: 50%;
  }
  .socialMediaShare.pull-left .mobShareIcon img {
    width: 20px;
  }
  .socialMediaShare.pull-left .socialShareIcons {
    position: absolute;
    background-color: #fff;
    top: -3px;
    left: 0;
    opacity: 0;
    padding: 5px;
    border-radius: 15px;
    pointer-events: none;
    display: flex;
    flex-direction: row;
    transition: all .4s ease-in-out;
    z-index: 1;
  }
  .socialMediaShare.pull-left .socialShareIcons button {
    margin: 0 5px;
  }
  .socialMediaShare.pull-left .socialShareIcons:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 0;
    height: 0;
    opacity: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #fff;
    transition: left .5s ease-out;
  }
}

@media screen and (max-width: 575px) {
  .socialMediaShare {
    top: calc(50vh - 20px);
  }
  .socialMediaShare button {
    font-size: 18px !important;
    width: 36px;
    height: 36px;
  }
}
