@import '../../styles/_colors.scss';
.AllEpisodes {
    .title {
        height: 48px;
        margin: 46px 156px 41px 139px;
        font-size: 37px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: 1.2px;
        text-align: center;
        color: $fTitle;
    }
    .buttonContainer {
        text-align: center;
        .showmoreBtn {
            width: 221px;
            height: 61px;
            margin: 46px 201px 76px 198px;
            padding: 7px 45px 6px 50px;
            border-radius: 5px;
            background-color: $pmColor;
            font-size: 22px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.18;
            letter-spacing: 1.2px;
            text-align: left;
            color: $pmFontcolor;
            outline: none;
        }
    }
}