@import '../../styles/animation.scss';


button{
    
    &.facebookIcon{
        border: 1px solid #320150 !important;
        background-color: #320150 !important;
        position: relative;
        &:hover{
            background-color: #3b5998!important;
            border-color:#3b5998 !important;
            color: #fff!important;
        }
    }
    &.twitterIcon{
        position: relative;
        border: 1px solid #320150 !important;
        background-color: #320150 !important;
        &:hover{
            background-color: #1da1f2!important;
            border-color:#1da1f2 !important;
            color: #fff !important;
        }
    }
    &.whatsappIcon{
        position: relative;
        border: 1px solid #320150 !important;
        background-color: #320150 !important;
        &:hover{
            background-color: #25d366 !important;
            border-color:#25d366 !important;
            color: #fff !important; 
        }
    } 
}
