.whoWeAre_content_wrapper{
    position: relative;
}
.aboutContentWrap{
    text-align: center;
    padding: 70px 0;
    font-size: 24px;
    line-height: 45px;
    h2{
        font-size: 37px;
        color: #162d3d;
        font-weight: 900;
        margin-bottom: 30px;
    }
    @media  (max-width:1366px){
        padding: 50px 0;
        font-size: 20px;
        line-height: 40px;
        
    }
    @media  (max-width:1024px){
        padding: 40px 0;
        font-size: 18px;
        line-height: 26px;
    }
    @media  (max-width:768px){
        font-size: 16px;
        line-height: 26px;
        h2{
            font-size: 30px;
            margin-bottom: 20px;
        }
    }
    :global .container{
        max-width: 1080px;
    }
}