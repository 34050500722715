.imageSlider {
    position: relative;
    z-index: 0;

    .HomeBanner {
        max-height: 460px;
        min-height: 460px;
        // background-attachment: fixed;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        top: 90px;
        cursor: pointer;

        img {
            width: 100% !important;
            visibility: hidden;
        }

    }

    span {
        margin-top: -14% !important;
    }
}


@media screen and (max-width:1199px) {}

@media screen and (max-width:1080px) {
    .imageSlider {
        .HomeBanner {
            max-height: 420px;
            min-height: 420px;
        }
    }
}

@media screen and (max-width:1024px) {
    .imageSlider {
        .HomeBanner {
            max-height: 400px;
            min-height: 400px;
        }
    }
}

@media screen and (max-width:991px) {}

@media screen and (max-width:767px) {
    .imageSlider {
        .HomeBanner {
            max-height: inherit !important;
            min-height: auto !important;
        }
    }

}

@media screen and (max-width:575px) {
    .imageSlider {
        .HomeBanner {
            min-height: 170px !important;
        }
    }
}

@media screen and (max-width:480px) {
    .imageSlider {
        .HomeBanner {
            min-height: 150px !important;
        }
    }
}

@media screen and (max-height:600px) and (max-width:767px) {}

@media screen and (max-height:600px) and (max-width:575px) {}

@media screen and (max-height:400px) and (max-width:991px) {}

@media screen and (max-height:360px) and (max-width:991px) {}