.scheduleBoxWrap{
    margin-top: 4%;
    
    .scheduleWrap{
        background-color: #ffffff;
        box-shadow: 0 14px 44px 0 rgba(0, 0, 0, 0.09);
        .ScheduleContainer{
            margin-top: 5%;
            margin-bottom: 5%;
            .scheduleImg{
                img{
                    width: 100%;
                    height: 307px;
                    object-fit: cover;
                }
            }
            .boxContent{
                padding: 4%;
                .title{
                    font-size: 25px;
                    font-weight: 700;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 0.96;
                    letter-spacing: normal;
                    text-align: left;
                    color: #000000;
                }
                .subtitle{
                    font-size: 17px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.41;
                    letter-spacing: 1.2px;
                    text-align: left;
                    color: #a7a4a7;
                    margin-top: 5%;
                }
                .disc{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    width: 74%;
        
                    font-size: 13px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.85;
                    letter-spacing: 1.2px;
                    text-align: left;
                    color: #747474;
                    margin-top: 4%;

                }
                .timeDuration{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .starttime{
                        font-size: 23px;
                        font-weight: 700;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.04;
                        letter-spacing: normal;
                        text-align: left;
                        color: #4f0c77;
                        display: inline-block;
                        flex: 1;
                    }
                    .durations{
                        padding: 6px 8px 3px 10px;
                        border-radius: 4px;
                        background-color: #ededed;
                        font-weight: 600;
                        display: inline-block;
                        
                    }
                }
                

            }
        }
    }
}



@media screen and (max-width:767px) {
    .scheduleBoxWrap{
        
        .scheduleWrap{
           
            .ScheduleContainer{
                
                
                .boxContent{
                    padding: 25px 40px;
    
                }
            }
        }
    }
}



@media screen and (max-width:480px) {
    .scheduleBoxWrap{
        
        .scheduleWrap{
           
            .ScheduleContainer{
                
                .scheduleImg{
                    img{
                        
                    }
                }
                .boxContent{
                    padding: 20px 30px;
                    .title{
                        line-height: 30px;
                    }
                    .subtitle{
                       
                    }
                    .disc{
                        
                    width: 100%;
                    }
                    .starttime{
                       
                    }
                    .durations{
                       
                    }
    
                }
            }
        }
    }
}