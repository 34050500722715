.innerBanner{
    min-height: 342px;
    max-height: 342px;
    position: relative;
    @media  (max-width:1024px){
        min-height: 300px;
        max-height: 300px;
    }
    @media  (max-width:768px){
        min-height: 250px;
        max-height: 250px;
    }
    @media  (max-width:480px){
        min-height: 180px;
        max-height: 180px;
    }
    :global .container{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
    }
    .bannerTitle{
        font-size: 54px;
        line-height:66px;
        font-weight: 900;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        @media  (max-width:1466px){
            font-size:46px;
            line-height: 54px;
        }
        @media  (max-width:1024px){
            font-size:36px;
            line-height: 44px;
        }
        @media  (max-width:768px){
            font-size:30px;
            line-height: 36px;
        }
    }
}