.videoContainer{
    margin-top: 5%;
    .videowrap{
        overflow: hidden;
        width: 100% !important;
        .modalClose{
            position: absolute;
            top: 10px;
            right: 10px;
            width: 35px;
            height: 35px;
            cursor: pointer;
            color: #000000;
            border-radius: 100%;
            background-color: #fff;
            font-size: 20px;
            text-align: center;
            line-height: 35px;
            box-shadow: 0px 3px 6px rgba(0,0,0,0.15);
            -webkit-box-shadow: 0px 3px 6px rgba(0,0,0,0.15);
            -moz-box-shadow: 0px 3px 6px rgba(0,0,0,0.15);
            z-index: 5;
            i{
                display: inline-block;
                font-style: normal;
                -webkit-transition: all 0.4s ease-in-out;
                -moz-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
            }
            &:hover{
                i{
                    transform: rotate(360deg);
                }
                
            }
        }
        
        video{
            position: relative;
        }
        .VideoPlayer{
            position: relative;
        }
        .videoBackground{
            background-color: black !important;
        }
    }
     
    .shareVideo{
        position: absolute;
        cursor: pointer;
        top: 30px;
        left: 25px;
        z-index: 1;
        color: #fff;
         span{
             display: inline-block;
             margin-right: 20px;
             position: relative;
             width: 32px;
             height: 32px;
             display: flex;
             justify-content: center;
             align-items: center;
             border-radius: 50%;
            //  border: 1px solid #fff;
         }
         
         .shareIcons{
             display: flex;
             opacity: 0;
             transition: all .5s ease-out;
             pointer-events: none;
             position: absolute;
             top:-3px;
             left: 0;
             background-color: #fff;
             padding: 5px;
             border-radius: 15px;
             &:after{
                 content: "";
                 position: absolute;
                 top: 0;
                 left: 0;
                 bottom: 0;
                 margin: auto;
                 width: 0;
                 height: 0;
                 opacity: 0;
                 border-top: 10px solid transparent;
                 border-bottom: 10px solid transparent;
                 border-right: 10px solid #fff;
                 transition: left .5s ease-out;
             }
             :global .mobShareIcon{
                 display: none;
             }
             :global .socialShareIcons{
                display: flex;
                flex-direction: row;
            }
             button{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px !important;
                color: #fff!important;
                width: 36px;
                height: 36px;
                border: 1px solid #320150 ;
                background-color: #320150 ;
                border-radius: 50%;
                text-decoration: none;
                transition: all .4s ease-in-out;
                margin: 0 5px;
                &:hover{
                    color: #fff;
                }
                &:first-child{
                     margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
            
                &.facebookIcon{
                    position: relative;
                    &:hover{
                        background: #3b5998 !important;
                        border-color:#3b5998 !important;
                        color: #fff!important;
                    }
                }
                &.twitterIcon{
                    position: relative;
                    &:hover{
                        background: #1da1f2 !important;
                        border-color:#1da1f2 !important;
                        color: #fff!important;
                    }
                }
                &.whatsappIcon{
                    position: relative;
                    &:hover{
                        background: #25d366 !important;
                        border-color:#25d366 !important;
                        color: #fff !important;
                    }
                } 

            }


         } 
         &:hover .shareIcons{
            opacity: 1;
            width: auto;
            height: auto;
            pointer-events: visible;
            left:100%;
            -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
            transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
            &:after{
                opacity: 1;
                left: -9px;
            }
         } 
    }
}

@media screen and (max-width:575px) {
    .videoContainer{
        margin-top: 30%;
    }
}

@media screen and (max-width:480px) {
    .videoContainer{
        margin-top: 30%;

        .videowrap{
            height: auto !important;
        }
    }
}