@import '../../styles/animation.scss';
.Crafts{
    background-color: #28013F;
    color: #ffffff;
    text-align: center;
    padding-bottom: 5%;
    :global .card{
        border: 0px;
        border-radius: 10px;
        max-width:400px;
        width:100%;
        margin:0 auto 30px;
        &:hover{
            box-shadow: 0px 0px 12px 6px rgba(0,0,0,0.17);
                -webkit-box-shadow: 0px 0px 12px 6px rgba(0,0,0,0.17);
                -moz-box-shadow: 0px 0px 12px 6px rgba(0,0,0,0.17);
                -webkit-animation-name: hvr-wobble-vertical;
                animation-name: hvr-wobble-vertical;
                -webkit-animation-duration: 1s;
                animation-duration: 1s;
                -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
                -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
        }
        :global .card-img-top{
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        :global .card-body{
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        
    }
    :global .container{
        max-width:860px !important;
    }
    .image{
        max-width:400px;
        width:100%;
        margin:auto;
        height: 224px;
        object-fit: cover;
    }  
    h1{
        padding: 40px 0;
        @media (max-width:767px){
            font-size: 2rem;
            padding: 30px 0 20px ;
        }
       }
    .cardBody{
        position: relative;
        background-color: #320150;
        box-shadow: 0px 3px 46px #00000067;
        :global .card-title{
            text-align: left;
            font-weight: 500;
        }
        :global .card-text{
            text-align: left;
            color: #BDA1D1;
        }
    }
    .downloadbtn{
        position: absolute;
        top: -52px;
        right: 25px;
        background: #FDB300;
        width: 72px;
        height: 72px;
        bottom: 129px;
        border-radius: 50%;
        padding: 20px;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        cursor: pointer;
        &:hover{
            -webkit-animation-name: buzz-out;
                animation-name: buzz-out;
                -webkit-animation-duration: .7s;
                animation-duration: .7s;
                -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
                -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1
        }
    }
}

