@import "../../styles/_colors.scss";
@import "../../styles/animation.scss";
.page_not_found_wrap {
  position: relative;
  z-index: 0;
  background-color: #fff;
  min-height: calc(100vh - 147px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 991px) {
    min-height: calc(100vh - 132px);
  }
  .imgfloatingTop,
  .imgfloatingBottom {
    position: absolute;
    margin: 0;
    padding: 0;
  }
  .imgfloatingTop {
    top: -77px;
    right: 0;
    img {
      max-width: 100%;
      @media (min-width: 767px) and (max-width: 1400px) {
        width: 21.786vw;
        height: 42.571vw;
      }
      @media (max-width: 767px) {
        width: 150px;
        height: auto;
      }
      @media (max-width: 580px) {
        width: 120px;
      }
    }
  }
  .imgfloatingBottom {
    bottom: 0;
    left: 0;
    img {
      max-width: 100%;
      @media (max-width: 1400px) {
        width: 36.143vw;
        height: 26.286vw;
      }
      @media (max-width: 767px) {
        width: 245px;
        height: auto;
      }
      @media (max-width: 580px) {
        width: 210px;
      }
    }
  }
  .page_not_found {
    position: relative;
    padding: 60px 0;
    max-width: 550px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    z-index: 1;
    .error_type {
      display: block;
      text-align: center;
      margin-bottom: 36px;
      @media (max-width: 991px) {
        max-width: 250px;
        width: 100%;
        margin: 0 auto 28px;
        img {
          max-width: 100%;
        }
      }
      @media (max-width: 767px) {
        max-width: 220px;
      }
    }
    .error_msg {
      display: block;
      font-size: 26px;
      line-height: 1;
      margin-bottom: 10px;
      text-align: center;
      font-weight: 600;
      @media (max-width: 991px) {
        font-size: 20px;
      }
      @media (max-width: 767px) {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
    .error_desc {
      display: block;
      margin-bottom: 18px;
      text-align: center;
      color: #000;
      margin-bottom: 56px;
      font-weight: 500;
      @media (max-width: 991px) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 40px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.2px;
        margin-bottom: 30px;
      }
    }
    :global {
      .btn {
        margin: 0 auto;
        max-width: 316px;
        width: 100%;
        border: 1px solid #fdb300;
        background-color: transparent !important;
        color: #320150 !important;
        font-size: 22px;
        font-weight: 600;
        text-transform: capitalize;
        height: 63px;
        line-height: 63px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s ease-in-out;
        @media (max-width: 991px) {
          max-width: 280px;
          height: 50px;
          line-height: 50px;
          font-size: 18px;
          padding: 0 15px;
        }
        @media (max-width: 767px) {
          max-width: 230px;
          height: 45px;
          line-height: 45px;
          font-size: 16px;
          padding: 0 15px;
        }
        .arrow {
          -webkit-transform: translateX(15px);
          transform: translateX(15px);
          @media (max-width: 767px) {
            -webkit-transform: translateX(10px);
            transform: translateX(10px);
          }
        }

        &:hover,
        &:focus,
        &:active {
          border: 1px solid #fdb300;
          background-color: #fdb300 !important;
          color: #320150 !important;
          .arrow {
            -webkit-animation-name: hvr-arrow;
            animation-name: hvr-arrow;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
          }
        }
      }
    }
  }
}
