@import "../../styles/_colors.scss";
// @import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700;900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
@import "../../styles/_animation.scss";
@import url("https://fonts.googleapis.com/css2?family=Carattere&family=Inter:wght@300;400;500;600;700&family=Lato:wght@300;400;700;900&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  font-family: "Inter", sans-serif;
}

body {
  position: relative;
  overflow-x: hidden;
  background-color: $backgroundColor;
}

html * {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

html ::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

html {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  scroll-behavior: smooth;
}

html ::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

html * {
  -ms-overflow-style: inherit;
  /* for Internet Explorer, Edge */
  scrollbar-width: 10px;
  /* for Firefox */
}

.mobMenu .topMenuSm {
  z-index: 1000000 !important;
}

// body::-webkit-scrollbar {
//     width: 10px;
//     background-color: #F5F5F5;
// }
// body::-webkit-scrollbar-track {
//     width: 10px;
//     background-color: #F5F5F5;
//     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// }

// body::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     background-color: #ffb800;
//     background-image: linear-gradient(to bottom, #ffb800, #ffad00, #ffa300, #ff9800, #ff8d00);
// }

// :global .socialMediaShare{
//     position: fixed;
//     top:20%;
//     right:30px;
//     display: flex;
//     flex-direction: column;
//    -webkit-animation-name: fixedShare;
//     animation-name: fixedShare;
//     -webkit-animation-duration: 0.75s;
//     animation-duration: 0.75s;
//     -webkit-animation-timing-function: linear;
//     animation-timing-function: linear;
//     -webkit-animation-iteration-count: 1;
//     animation-iteration-count: 1;

//     button{
//         margin: 5px 0;
//         svg{
//             width: 40px;
//             height: 40px;
//         }
//     }

// }

button:focus,
input:focus,
select:focus,
a:focus {
  outline: none;
}

.main_header {
  position: relative;
  z-index: 99999;
}

.appContent {
  width: 100%;
  padding-top: 79px;

  @media screen and (max-width:1024px) {
    padding-top: 0px;
  }
}

.showMenuWrapper,
.showMenuHolder {
  position: relative;
}

.showMenuHolder {
  background-color: #fff;
  z-index: 1;
}

.showMenuWrapper {
  position: relative;
  z-index: 4;

  &:hover {
    z-index: 11;
  }
}

.showMenuInserted,
.showMenuAppend {
  position: relative;
}

.TopMenuWrapper {
  box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.15);
  // border-bottom: 3px solid #fff;

  &.sliderMenuVisible {
    box-shadow: none;
    border-bottom: 0;
  }
}

.btnShowMenuHolder {
  position: absolute;
  bottom: -16px;
  left: -1.5%;
  right: 0;
  width: 40px;
  height: 40px;
  margin: auto;
  border-radius: 50%;
  z-index: 10;
  transform: rotate(180deg);
  transition: all 0.5s ease-in-out;

  &:after {
    content: "";
    position: absolute;
    width: 47px;
    height: 24px;
    left: 50%;
    transform: translateX(-50%);
    margin: auto;
    z-index: -1;
    bottom: -3px;
    border-bottom-left-radius: 58px;
    border-bottom-right-radius: 58px;
    border-top: 0;
    background-color: #fff;
  }

  .btnShowMenu {
    width: 40px;
    height: 40px;
    background-color: #45046c;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgb(0 0 0 / 0%);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    position: relative;
    top: 0;

    &:hover {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }

    .arrow {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 26px;
      height: 11px;
      transform: rotate(180deg);
      transition: all 0.5s ease-in-out;

      img {
        display: block;
        max-width: 100%;
      }
    }
  }

  &.active {
    transform: rotate(0deg);

    .btnShowMenu {
      .arrow {
        transform: rotate(180deg);
      }
    }

    &:after {
      box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.15);
    }
  }
}

.maincontent {
  overflow-x: hidden;

  .swplogo {
    width: 100%;
    text-align: center;

    img {
      margin: auto;
      max-height: 85px;
      -webkit-transform-origin: 100% 0;
      transform-origin: 100% 0;
      -webkit-animation-name: wobble-bottom;
      animation-name: wobble-bottom;
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
  }

  .dropShowmenu {
    // display: none  !important;
    width: 50px;
    height: 47px;
    background-color: $pmColor;
    margin: 0 auto;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1000;
    top: 123px;
    cursor: pointer;

    .arrow {
      border: solid 2px #fffdfa;
      border-width: 0 4px 4px 0;
      display: inline-block;
      padding: 7px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      left: 33%;
      position: absolute;
      top: 16px;
    }

    &:hover {
      -webkit-animation-name: hvr-wobble-vertical;
      animation-name: hvr-wobble-vertical;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
  }
}

.ListingLayout {
  .title {
    font-size: 37px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 1.2px;
    text-align: center;
    color: $fTitle;
    padding-top: 50px;
    padding-bottom: 45px;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }

  .titlePadding {
    padding-top: 100px;
  }

  .buttonContainer {
    text-align: center;

    .showmoreBtn {
      max-width: 240px;
      width: 100%;
      height: 61px;
      margin: 46px 0 76px 0;
      padding: 7px 45px 6px 50px;
      border-radius: 5px;
      background-color: $pmColor;
      font-size: 22px;
      font-weight: 400;
      line-height: 2.18;
      letter-spacing: 1.2px;
      text-align: center;
      color: $pmFontcolor;
      outline: none;
      border: 0;
    }
  }
}

:global .episode-dshimmer :global .react-loading-skeleton {
  height: calc(100vh - 160px) !important;
}

:global .btn-primary {
  background-color: #fdb300;
  color: #28013f;
  border: 0px;

  &:hover {
    background-color: #fdb300;
  }
}

:global .btn-secondary {
  background-color: #ffffff;
  color: #28013f;
  border: 0px;

  &:hover {
    background-color: #fdb300;
  }
}

button {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);

  &:hover,
  &:focus,
  &:active {
    -webkit-animation-name: hvr-wobble-skew;
    animation-name: hvr-wobble-skew;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }
}

.btnViolet {
  border: 2px solid #320150;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 2px solid #320150;
    color: #320150;
    background: #f7f7f7;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    border-radius: 5px;
  }

  &:hover,
  &:focus {
    color: #320150 !important;

    &:before,
    &:before {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
}

@media screen and (max-width: 1466px) {
  .ListingLayout {
    .title {
      font-size: 32px;
    }
  }
}

@media screen and (max-width: 1199px) {
  .ListingLayout {
    .title {
      padding-bottom: 8%;
      margin-bottom: 35px;
    }
  }
}

@media screen and (max-width: 1080px) {}

@media screen and (max-width: 991px) {
  .ListingLayout {
    .title {
      font-size: 30px;
      padding-top: 5%;
      padding-bottom: 6%;
    }
  }
}

@media screen and (max-width: 767px) {
  .maincontent {
    .swplogo {
      display: none;
    }

    .dropShowmenu {
      top: 14vw;
    }
  }

  .ListingLayout {
    .title {
      padding-top: 6%;
      padding-bottom: 6%;
    }
  }

  // .showMenuHolder{
  //     z-index: 10001;
  //     &.showMenuInserted{
  //         z-index: 900;
  //     }
  // }
}

@media screen and (max-width: 575px) {
  .ListingLayout {
    .title {
      padding-top: 8%;
      padding-bottom: 8%;
    }
  }

  .btnShowMenuHolder {
    bottom: -17px;
    width: 42px;
    height: 42px;

    &:after {
      bottom: -2px;
    }

    &:after,
    &:before {
      width: 50px;
    }

    .btnShowMenu {
      width: 42px;
      height: 42px;
      top: -2px;

      .arrow {
        width: 22px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .maincontent {
    .dropShowmenu {
      top: 17vw;
    }
  }

  .ListingLayout {
    .title {
      font-size: 26px;
      margin: 0px;
      padding-top: 30px;
      padding-bottom: 0;
      margin-bottom: 40px;
    }

    .buttonContainer {
      text-align: center;

      .showmoreBtn {
        margin: 0px;
        max-width: 155px;
        height: 45px;
        padding: 0px;
        text-align: center;
        margin-bottom: 11%;
        font-size: 14px;
      }
    }

    .titlePadding {
      padding-top: 30px;
    }
  }
}

@media screen and (max-width: 375px) {
  .ListingLayout {
    .title {
      font-size: 22px;
    }
  }
}

@media screen and (max-height: 600px) and (max-width: 767px) {}

@media screen and (max-height: 600px) and (max-width: 575px) {}

@media screen and (max-height: 400px) and (max-width: 991px) {}

@media screen and (max-height: 360px) and (max-width: 991px) {}