@import '../../styles/_colors.scss';
@import '../../styles/animation.scss';
.ShowThumb {
    padding-bottom: 60px;
    .imgContainer {
        height: 460px;
        background-color: $pmFontcolor;
        box-shadow: 0 14px 44px 0 rgba(0, 0, 0, 0.09);
        .featureImg {
            width: 100%;
            height: 409px;
        }
        .featureName {
            // font-family: Nexa;
            font-size: 17px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.41;
            letter-spacing: 1.2px;
            text-align: left;
            color: #303030;
            padding: 5%;
        }
    }
     :global .react-loading-skeleton {
        margin-bottom: 20px;
    }
    .showBox {
        padding: 0px 12px;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        
        .showWrap {
            transition: all .3s ease-out;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            cursor: pointer;
            
            &:hover {
                // transform: translate(0, -5px);
                -webkit-animation-name: hvr-wobble-vertical;
                animation-name: hvr-wobble-vertical;
                -webkit-animation-duration: 1s;
                animation-duration: 1s;
                -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
                -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
            }
        }
        img {
            width: 100%;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            -moz-top-left-radius: 6px; 
            -moz-top-right-radius: 6px; 
            -webkit-top-right-radius: 6px;
            -webkit-top-left-radius: 6px;
            cursor: pointer;
            object-fit: cover;
            // height: 409px;
            height: 360px;
            -webkit-transition: 0.4s ease;
            transition: 0.4s ease;
            -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
            -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
            -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
        }
        .content {
            font-size: 17px;
            font-weight: 700;
            padding: 20px 15px;
            background-color: #fff;
            margin-bottom: 27px;
            box-shadow: 0 14px 44px 0 rgba(0, 0, 0, 0.09);
            width: 100%;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            display: flex;
            align-items: center;
            height: 66px;
            p{
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;  
                overflow: hidden;
                margin: 0;
            }
        }
        .showImage {
            overflow: hidden;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            &:hover img {
                -webkit-transform: scale(1.08);
                transform: scale(1.08);
            }
        }
    }
}


@media screen and (max-width:1199px) {
    .ShowThumb {
        padding-bottom: 50px;
        .showBox {
           
            img {
                max-height: 285px;
            }
        }
    }
}

@media screen and (max-width:1080px) {
    .ShowThumb {
        .showBox {
            img {
                height: inherit;
                max-height: 200px;
            }
            .content {
                margin-bottom: 30px;
            }
        }
         :global .react-loading-skeleton {
            height: 0px !important;
            padding-bottom: 120%;
        }
    }
}

@media screen and (max-width:991px) {}

@media screen and (max-width:767px) {
    .ShowThumb {
        padding-bottom: 40px;
        .showBox {
           
            img {
                max-height: 300px;
            }
        }
    }
}

@media screen and (max-width:575px) {}

@media screen and (max-width:480px) {
    .ShowThumb {
        padding-bottom: 30px;
        .imgContainer {
            .featureImg {}
            .featureName {}
        }
        .showBox {
            img {
                height: 220px;
            }
            .content {
                font-size: 15px;
                width: 100%;
                overflow: hidden;
                margin-bottom:20px;
            }
        }
    }
}

@media screen and (max-height:600px) and (max-width:767px) {}

@media screen and (max-height:600px) and (max-width:575px) {}

@media screen and (max-height:400px) and (max-width:991px) {}

@media screen and (max-height:360px) and (max-width:991px) {}