
@import '../../styles/_colors.scss';

.buttons{
    background-color: $fbackgroundColor;
    text-align: center;
    padding-bottom: 6%;

    .upload{
     text-align: right;
    }
    .uploadBtn{
     width: 211px;
     height: 55px;
     border-radius: 5px;
     background-color: #ffffff;
     font-size: 22px;
     font-weight: 500;
     font-stretch: normal;
     font-style: normal;
     line-height: 2.18;
     letter-spacing: 1.2px;
     color: #320150;
     padding-top: 3px;
    }
    .galleryBtn{
     width: 211px;
     height: 55px;
     border-radius: 5px;
     background-color: #ffffff;
     margin-left: 32px;
     font-size: 22px;
     font-weight: 500;
     font-stretch: normal;
     font-style: normal;
     line-height: 2.18;
     letter-spacing: 1.2px;
     color: #320150;
     text-align: center;
    }
}
.container{
    overflow: hidden;
}
.EpisodeContentWrap{
    padding: 40px 30px 40px 0;
    background-color: #28013f;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 0.05px;
    font-weight: 400;
    .EpisodeContent{
        padding-left: 120px;
        max-width: 83.33%;
        width: 100%;
    }
    h2{
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 16px;
    }
    > span{
        display: block;
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: 400;
    }
    article, p{
        font-size: 18px;
        letter-spacing: 0.05;
        font-weight: 400;
        p{
            margin-bottom: 15px;
        }
    }
}

@media only screen and (max-width: 1466px)
{
    .container{
        width: 100%;  
        max-height: 550px;
      }

    .EpisodeContentWrap{
        padding: 40px 20px 40px 0;
        font-size: 17px;

        .EpisodeContent{
            padding-left: 100px;
        
            > h2{
                font-size: 28px;
            }
            > span{
                display: block;
                margin-bottom: 10px;
                font-size: 15px;
                font-weight: 400;
            }
            article, p{
                font-size: 17px;
                p{
                    margin-bottom: 15px;
                }
            }
        }
    }
}

.row{
    margin-right:0px !important ;
}

.noPadding{
    padding: 0 !important;
    position: relative;
}


.noMargin{
    margin: 0;
    background-color: #28013e;
}
.videoWrapper{
    overflow: hidden;
    background-color: #000;
    position: relative;
    // display: flex;
    // align-items: center;
     z-index: 1;

     @media only screen  and (max-width: 1466px){
         min-height: 37vw ;
    }
     
    video{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: 0;

    }
    
}
.videoListWrapper{
    padding:0 15px;
    position: relative;
    min-height: 39vw;
    overflow: hidden; 
    min-height: 39vw;
}

@media screen and (max-width:767px) {
  
    .episodeVideoBanner{
        display:block;
        .videoWrapper,.videoListWrapper{
            width: 100%;
            max-width: 100%;
            min-height: auto;
        }
    }
    .videoWrapper{
        video{
            position: relative;
        }
    }
    .videoListWrapper{
        position: relative;
        width: 100%;
        max-width: 100%;
        min-height: auto;
        overflow: visible;
    }
    .container{
        width: 100%;  
        max-height: none;
      }
    .EpisodeContentWrap{
        padding: 30px 20px 30px 20px;
        font-size: 16px ;
   
        .EpisodeContent{
            padding-left: 0;
            max-width: 100%;
            >h2{
                font-size: 26px;
                margin-bottom: 10px;
            }
            > span{
                margin-bottom: 6px;
                font-size: 14px;
            }
            article, p{
                font-size: 16px;
                p{
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@media screen and (max-width:480px) {

    .buttons{
        margin-left: -45%;
        .uploadBtn{
            width: 130px !important;

        }
        .galleryBtn{
            width: 130px  !important;
            position: absolute;
            line-height: normal !important;

        }
    }
    .EpisodeContentWrap{
        padding: 30px 15px 30px 15px;
        .EpisodeContent{
            >h2{
                font-size: 24px;
            }
        }
    }
}