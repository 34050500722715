.live_img {
    @media screen and (max-width:1440px) {
        height: 40px;
        width: 50px;
    }

    @media screen and (max-width:1024px) {
        height: unset;
        width: unset;
    }
}


@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.blink {
    animation: blink 1s infinite;
}