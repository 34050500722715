
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fixedShare {
  0%{
      top:-100%
  }
  100% {
      top:50%
  }
}
@keyframes fixedShare {
  0%{
      top:-100%
  }
  100% {
      top:50%
  }
}


@-webkit-keyframes hvr-wobble-skew {
    16.65% {
      -webkit-transform: skew(-12deg);
      transform: skew(-12deg);
    }
    33.3% {
      -webkit-transform: skew(10deg);
      transform: skew(10deg);
    }
    49.95% {
      -webkit-transform: skew(-6deg);
      transform: skew(-6deg);
    }
    66.6% {
      -webkit-transform: skew(4deg);
      transform: skew(4deg);
    }
    83.25% {
      -webkit-transform: skew(-2deg);
      transform: skew(-2deg);
    }
    100% {
      -webkit-transform: skew(0);
      transform: skew(0);
    }
  }
  @keyframes hvr-wobble-skew {
    16.65% {
      -webkit-transform: skew(-12deg);
      transform: skew(-12deg);
    }
    33.3% {
      -webkit-transform: skew(10deg);
      transform: skew(10deg);
    }
    49.95% {
      -webkit-transform: skew(-6deg);
      transform: skew(-6deg);
    }
    66.6% {
      -webkit-transform: skew(4deg);
      transform: skew(4deg);
    }
    83.25% {
      -webkit-transform: skew(-2deg);
      transform: skew(-2deg);
    }
    100% {
      -webkit-transform: skew(0);
      transform: skew(0);
    }
  }

  
  @-webkit-keyframes buzz-out {
    10% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg)
    }
    20% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg)
    }
    30% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg)
    }
    40% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg)
    }
    50% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg)
    }
    60% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg)
    }
    70% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg)
    }
    80% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg)
    }
    90% {
        -webkit-transform: translateX(1px) rotate(0);
        transform: translateX(1px) rotate(0)
    }
    100% {
        -webkit-transform: translateX(-1px) rotate(0);
        transform: translateX(-1px) rotate(0)
    }
}

@keyframes buzz-out {
    10% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg)
    }
    20% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg)
    }
    30% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg)
    }
    40% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg)
    }
    50% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg)
    }
    60% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg)
    }
    70% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg)
    }
    80% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg)
    }
    90% {
        -webkit-transform: translateX(1px) rotate(0);
        transform: translateX(1px) rotate(0)
    }
    100% {
        -webkit-transform: translateX(-1px) rotate(0);
        transform: translateX(-1px) rotate(0)
    }
}


  @-webkit-keyframes hvr-buzz-out {
    10% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    20% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
    30% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    40% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
    50% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg);
    }
    60% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg);
    }
    70% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg);
    }
    80% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg);
    }
    90% {
      -webkit-transform: translateX(1px) rotate(0);
      transform: translateX(1px) rotate(0);
    }
    100% {
      -webkit-transform: translateX(-1px) rotate(0);
      transform: translateX(-1px) rotate(0);
    }
  }
  @keyframes hvr-buzz-out {
    10% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    20% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
    30% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    40% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
    50% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg);
    }
    60% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg);
    }
    70% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg);
    }
    80% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg);
    }
    90% {
      -webkit-transform: translateX(1px) rotate(0);
      transform: translateX(1px) rotate(0);
    }
    100% {
      -webkit-transform: translateX(-1px) rotate(0);
      transform: translateX(-1px) rotate(0);
    }
  }
.btnBuzz{
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  
  &:hover, &:focus {
    -webkit-animation-name: hvr-buzz-out;
    animation-name: hvr-buzz-out;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }
}

  
@-webkit-keyframes hvr-push {
  0%{
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25%{
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes hvr-push {
  0%{
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25%{
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}


@-webkit-keyframes wobble-bottom {
    16.65% {
      -webkit-transform: skew(-12deg);
      transform: skew(-12deg);
    }
    33.3% {
      -webkit-transform: skew(10deg);
      transform: skew(10deg);
    }
    49.95% {
      -webkit-transform: skew(-6deg);
      transform: skew(-6deg);
    }
    66.6% {
      -webkit-transform: skew(4deg);
      transform: skew(4deg);
    }
    83.25% {
      -webkit-transform: skew(-2deg);
      transform: skew(-2deg);
    }
    100% {
      -webkit-transform: skew(0);
      transform: skew(0);
    }
  }
  @keyframes wobble-bottom {
    16.65% {
      -webkit-transform: skew(-12deg);
      transform: skew(-12deg);
    }
    33.3% {
      -webkit-transform: skew(10deg);
      transform: skew(10deg);
    }
    49.95% {
      -webkit-transform: skew(-6deg);
      transform: skew(-6deg);
    }
    66.6% {
      -webkit-transform: skew(4deg);
      transform: skew(4deg);
    }
    83.25% {
      -webkit-transform: skew(-2deg);
      transform: skew(-2deg);
    }
    100% {
      -webkit-transform: skew(0);
      transform: skew(0);
    }
  }
@-webkit-keyframes hvr-arrow {
    0% {
    -webkit-transform:translateX(15px);
    transform: translateX(15px);
  }
  50% {
  -webkit-transform:  translateX(25px);
  transform:  translateX(25px);
  }
  100% {
  -webkit-transform:translateX(15px);
  transform: translateX(15px);
  }
}
@keyframes hvr-arrow {
  0% {
    -webkit-transform:translateX(15px);
    transform: translateX(15px);
    
  }
   50% {
    -webkit-transform:  translateX(25px);
    transform:  translateX(25px);
   }
   100% {
    -webkit-transform:translateX(15px);
    transform: translateX(15px);
  }
 
}
@media (max-width:767px) {
  @-webkit-keyframes hvr-arrow {
    0% {
      -webkit-transform:translateX(10px);
      transform: translateX(10px);
      
    }
     50% {
      -webkit-transform:  translateX(20px);
      transform:  translateX(20px);
     }
     100% {
      -webkit-transform:translateX(10px);
      transform: translateX(10px);
    }
   
  }
  @keyframes hvr-arrow {
    0% {
      -webkit-transform:translateX(10px);
      transform: translateX(10px);
      
    }
     50% {
      -webkit-transform:  translateX(20px);
      transform:  translateX(20px);
     }
     100% {
      -webkit-transform:translateX(10px);
      transform: translateX(10px);
    }
   
  }
}


