    .noData{
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 1.2px;
        color: #a7a4a7;
        padding: 5%;
        margin-top: -5%;
    }
