.restrictionModal{
    color: #000;
    .modalClose{
        position: absolute;
        top: 10px;
        right: 10px;
        width: 35px;
        height: 35px;
        cursor: pointer;
        color: #000000;
        border-radius: 100%;
        background-color: #fff;
        font-size: 20px;
        text-align: center;
        line-height: 35px;
        // box-shadow: 0px 3px 6px rgba(0,0,0,0.15);
        // -webkit-box-shadow: 0px 3px 6px rgba(0,0,0,0.15);
        // -moz-box-shadow: 0px 3px 6px rgba(0,0,0,0.15);
        z-index: 5;
        i{
            display: inline-block;
            font-style: normal;
            -webkit-transition: all 0.4s ease-in-out;
            -moz-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
        }
        &:hover{
            i{
                transform: rotate(360deg);
            }
            
        }
    }
    :global .modal-dialog {
        max-width: 680px;
    }
    :global .modal-content{
       
        .modal-body{
            padding: 30px 20px;
            text-align: center;
            font-size: 18px;
            line-height: 24px;
            @media (max-width:575px) {
                padding: 25px 15px;
                font-size: 16px;
            }
            
            h3{
                font-size: 44px;
                line-height: 50px;
                font-weight: 900;
                margin-bottom: 20px;
                color: #320150;
                @media (max-width:575px) {
                    font-size: 34px;
                    line-height: 40px;
                }
            }
            p{
                font-size: 18px; 
                line-height: 34px;
                font-weight: normal;
                strong{
                    font-size: 18px; 
                    line-height: 34px;
                    font-weight:600;
                }
                @media (max-width:575px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            
        }
        
        .btn{
                font-weight: 700;
                padding: 10px 20px;
            a{
                text-decoration:none !important;
            }
        }
        .btn-primary{
            background-color:#320150 !important;
            color:#fff !important;
            text-decoration:none !important;
            a{
                color:#fff !important;
            }
        }
        .btn-secondary{
            color:#320150;
            background-color: #FDB300;
        }
        
    }
}



