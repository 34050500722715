@import "../../../../styles/variables";

// @font-face {
//   font-family: "Gotham";
//   // src: url("../../../../assets/font/GothamBold.ttf") format("truetype");
//   font-weight: bold;
//   font-style: normal;
// }

.mobHeader {
  position: relative !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1600;
  background-color: #000;
  height: 80px;
  display: flex;
  align-items: center;
  background: $white;
  // box-shadow: 0px 2px 4px hsla(0, 0%, 0%, 0.08);
  background: linear-gradient(258deg, #87e0c5 -6.75%, #fec249 104.04%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @include transition(all, 0.3s, ease-in-out);

  .container {
    width: 100%;
  }

  .menu_wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 30px;

    @media screen and (max-width: 414px) {
      padding: 0 10px;
    }

    .first_section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 35px;

      figure {
        height: 38px !important;
        width: 38px !important;
        border-radius: 4px;
        margin: auto;

        &.active {
          background: rgba(255, 255, 255, 0.3);
        }

        .waffle_icon img {
          height: 100%;
          width: 100%;
          padding: 8px;
        }
      }

      @media screen and (max-width: 420px) {
        gap: 20px;
      }
    }

    .second_section {
      display: flex;
      flex-direction: row;
      gap: 20px;

      a {
        text-decoration: none;
      }

      @media screen and (max-width: 420px) {
        gap: 10px;
      }
    }

    .menu_icon {
      display: flex;
      width: 50px;
      justify-content: end;
      align-items: center;

      cursor: pointer;

      figure {
        width: 100%;
        height: 25px;
        margin: auto;
        img {
          height: 25px;
          width: 35px;
        }
      }

      @media screen and (max-width: 540px) {
        padding-left: 0px;
      }

      @media screen and (max-width: 420px) {
        width: 30px;
      }
    }

    .waffle_icon {
      cursor: pointer;
      height: 100%;
      width: 100%;
      padding: 8px;

      // @media screen and (max-width:420px) {
      //     width: 20px;
      // }
    }

    .prayer_logo {
      width: 106px;
      height: 36.705px;

      @media screen and (max-width: 420px) {
        width: 100px;
      }
    }

    .waffle_wrapper {
      background: #fff;
      box-shadow: 0px 23px 46px 0px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 73px;
      left: 0;
      z-index: 50;
      height: auto;
      padding: 30px 25px 47px;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-10px);
      transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;

      @media screen and (max-width: 767px) {
        top: 73px;
        width: 100%;
      }

      @media screen and (max-width: 414px) {
        top: 58px !important;
        padding: 30px 25px 47px;
      }
      &.show {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        transform: translateY(0);
      }

      .iconWrapper {
        display: inline-flex;
        gap: 5px;

        .home_icon {
          padding-bottom: 10px;
        }
      }

      .title {
        color: #000;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 5px 0px 5px 0px;
      }

      .waffle_menu {
        display: grid;
        gap: 20px;
        grid-template-columns: calc(33.33% - 7px) calc(33.33% - 7px) calc(
            33.33% - 7px
          );

        @media screen and (max-width: 767px) {
          gap: 10px;
        }

        .child_container {
          border-radius: 8px;
          background: rgba(0, 0, 0, 0.08);
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          gap: 20px;
          width: 116px;
          height: 94px;
          flex-shrink: 0;
          padding: 10px;
          border: 1px solid transparent;
          @media screen and (max-width: 414px) {
            width: calc(33.33% - 7px);
          }
          @media screen and (max-width: 767px) {
            padding: 10px;
          }

          figure {
            margin: 0 auto 24px;
            max-width: 100%;
            display: block;
            margin: auto;
            max-height: 61px;
            height: 100%;
            transition: none !important;

            img {
              max-width: 100%;
              display: block;
              margin: auto;
              max-height: 61px;
              height: auto;
              transition: none !important;
            }
          }

          @media screen and (max-width: 767px) {
            width: 100%;
          }

          &:hover {
            border: 1px solid rgba(202, 150, 62, 0.4);
          }

          &.active {
            border: 1px solid rgba(202, 150, 62, 0.4);
          }
          span {
            text-decoration: none;
            color: #000;
            text-align: center;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          img {
            display: inline-block;
            // height: 40px;
            width: auto;
          }
        }

        a {
          text-decoration: none;
        }

        .home_card {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 10px;
          width: 100%;

          img {
            width: 36px;
            height: 36px;
            flex-shrink: 0;
          }
        }
      }
    }

    button {
      display: flex;
      width: 99px;
      height: 46px;
      padding: 15px 37px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border: 0.5px solid #fcb122;
      cursor: pointer;

      @media screen and (max-width: 420px) {
        width: 60px;
        height: 36px;
      }
    }
  }
  @keyframes expandDrawer {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes collapseDrawer {
    0% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  }

  .drawer_wrapper {
    position: absolute;
    top: 73px;
    left: 0;
    right: 0;
    background-color: white;
    // min-height: 70vh;
    width: 100%;
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none;
    padding-bottom: 15px;

    // Set a default transition for opacity and transform
    transition: opacity 0.3s ease-in-out 0.1s, transform 0.3s ease-in-out 0.1s;

    @media screen and (max-width: 414px) {
      top: 58px;
    }

    &.show {
      animation: expandDrawer 0.3s ease-in-out 0.1s forwards;
      pointer-events: auto;
    }

    &.hide {
      animation: collapseDrawer 0.3s ease-in-out forwards;
      pointer-events: none;
    }

    .search_bar {
      height: 70px;
      background: #d9d9d9;
      transition: background 0.3s ease-in-out 0.1s;

      @media screen and (max-width: 414px) {
        height: 50px;
      }
    }
  }

  .dropdown_wrapper {
    padding: 5px 30px;

    @include transition(all, 0.3s, ease-in-out);

    @media screen and (max-width: 414px) {
      padding: 19px 15px;
    }
    img {
      width: 60px;
    }

    .live_img {
      // width: 100px;
      // height: 40px;
      flex-shrink: 0;
      cursor: pointer;
    }

    .dropdown_container {
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      @include transition(all, 0.3s, ease-in-out);

      a {
        text-decoration: none;
        color: inherit;
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        &:hover {
          cursor: pointer;
        }
      }

      .dropdown_container1 {
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        padding: 19px 0px 16px;
        &:hover {
          cursor: pointer;
        }

        div {
          color: #000;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        @media screen and (max-width: 414px) {
          padding: 19px 0px 16px;
        }
        span {
          color: #323232;
          font-family: "Gotham", sans-serif;
          /* Fallback to sans-serif */
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          width: 100%;
        }
      }

      .submenu {
        display: flex;
        flex-direction: column;
        padding: 12px 58px;
        text-transform: uppercase;

        @media screen and (max-width: 414px) {
          padding: 0 0 0 28px;
        }

        a {
          text-decoration: none;
          padding: 11px 0px;

          @media screen and (max-width: 414px) {
            padding: 0px 0px 22px;

            &:first-child {
              padding: 0px 0px 22px;
            }

            &:last-child {
              padding: 0px 0px 16px;
            }
          }
        }

        span {
          color: #000;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }

  .live_button_wrapper {
    padding: 30px;
    @include transition(all, 0.3s, ease-in-out);
  }

  .search_wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 23px 30px;

    @media screen and (max-width: 414px) {
      padding: 10px 15px 10px 15px;
    }

    form {
      width: 100%;
    }

    input {
      background-color: transparent;
      border: none;
      width: 100%;
      color: rgba(0, 0, 0, 0.4);
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @media screen and (max-width: 414px) {
        line-height: 30px;
      }
    }
  }

  .donate {
    display: flex;
    width: 99px;
    height: 46px;
    padding: 15px 37px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;

    @media screen and (max-width: 420px) {
      width: 60px;
      height: auto;
    }
  }
}

// @media screen and (max-width: 540px) {
//   .mobHeader {
//     .menu_wrapper {
//       .waffle_wrapper {
//         top: 73px;
//         padding: 20px 20px;
//         width: 100%;

//         .waffle_menu {
//           width: 100%;

//             .child_container {
//               width: 25vw;

//           .waffle_img {
//               max-width: 60px;
//           }
//             }

//           .home_card {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             flex-direction: column;
//             gap: 10px;
//           }
//         }
//       }
//     }
//   }
// }
