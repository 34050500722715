@import '../../styles/_colors.scss';

@import '../../styles/animation.scss';

.showsmenu {
    position: relative;
    z-index: 1;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -ms-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    text-align: center;
    li{
        width: 110px !important;
        padding: 0 15px;
    }
    .item {
        // max-width: 87px;
        // width: 100%;
        border-radius: 100%;
        margin: auto;
        padding: 16px 0 0;
        position: relative;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        
        img {
            width: 87px;
            cursor: pointer;
            // transform: scale(1,1) translate(0, 0);
            -webkit-transition: all .4s ease-in-out;
            -moz-transition: all .4s ease-in-out;
            -ms-transition: all .4s ease-in-out;
            -o-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
            border-radius: 100%;
            display: block;
            margin: auto;
            -webkit-transform: perspective(1px) translateZ(0);
              transform: perspective(1px) translateZ(0);
            -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
            -webkit-animation-duration: 2s;
            animation-duration: 2s;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
            -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
            -webkit-animation-delay: 1s;
            animation-delay: 1s;
            
        }
        &:hover{
            img{
                transform: scale(1.1,1.1) translate(0, -5px);
                
            }
            span{
                display: block;
                top: 120%;
                opacity: 1;
                width: auto;
                height: auto;
                visibility: visible;
            }
        }
        span{
            // display: none;
            width: 0;
            height: 0;
            opacity: 0;
            transition: top .3s ease-in-out;
            position: absolute;
            top:130%;
            left: 50%;
            transform: translateX(-50%);
            padding: 13px 30px 10px;
            border-radius: 15px;
            text-align: center;
            background-color: #fff;
            font-size: 17px;
            font-weight: 700;
            color: #000000;
            white-space: nowrap;
            box-sizing: border-box;
            box-shadow: 0px 3px 16px 0px rgba(0,0,0,0.3);
            visibility: hidden;
         
            &::before{
                content: "";
                position: absolute;
                width: 20px;
                height: 20px;
                background: #fff;
                transform: translateX(-50%) translateY(-50%) rotate(45deg);
                top: 0;
                left: 50%;
                box-sizing: border-box;
                box-shadow:-2px -2px 3px 0 rgba(0, 0, 0, 0.1);  
                z-index: -1;           
            }

        }

    }
     
    .react-multi-carousel-list {
        display: block !important;
    }

  
  
    .carouselcontainer {
        overflow: visible !important;
        text-align: center;
        button {
            opacity: 0;
            display: block !important;
            position: absolute;
            outline: 0;
            transition: all .5s;
            border-radius: 50%;
            top: 50% !important;
            transform: translateY(-50%);
            z-index: 1000;
            box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.13);
            background-color: #ffffff;
            width: 86px;
            height: 86px;
            opacity: 1;
            color: #000000 !important;
            right: -22px;
            top: 38px;
            opacity: 0;
            -webkit-transition: all .4s ease-in-out;
            -moz-transition: all .4s ease-in-out;
            -ms-transition: all .4s ease-in-out;
            -o-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
            -webkit-animation: none !important;
            -moz-animation: none !important;
            -o-animation: none !important;
            -ms-animation: none !important;
            animation: none !important;
        }

        :global .react-multi-carousel-track {
            margin: auto;
        }
       
        :global .react-multiple-carousel__arrow--right {
            right: -150px;
        }
        :global .react-multiple-carousel__arrow--left {
            left: -150px;
        }
        :global .react-multiple-carousel__arrow--left:after {
            content: "";
            border: solid black;
            border-width: 0 5px 5px 0;
            display: inline-block;
            padding: 6px;
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
            position: absolute;
            top: 35px;
            left: 0;
        }
         :global .react-multiple-carousel__arrow--left:before,
         :global .react-multiple-carousel__arrow--right:before {
            display: none !important;
        }
         :global .react-multiple-carousel__arrow--right:after {
            content: "";
            border: solid black;
            border-width: 0 5px 5px 0;
            display: inline-block;
            padding: 6px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            position: absolute;
            top: 35px;
            right: 0;
        }
        :global .react-multiple-carousel__arrow--left:after,:global .react-multiple-carousel__arrow--right:after{
            opacity: 0;
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -ms-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
}
    }


    .carouselcontainer{
        &:hover{
            button {
                opacity: 1;
    
            }
            :global .react-multiple-carousel__arrow--right {
                right: -32px !important;
                height: 90px;
                width: 90px;
            }
            :global .react-multiple-carousel__arrow--left {
                right: -32px !important;
                height: 90px;
                width: 90px;
            }
            :global .react-multiple-carousel__arrow--left {
                left: -36px;
            }
            :global .react-multiple-carousel__arrow--left:after,:global .react-multiple-carousel__arrow--right:after{
                opacity: 1;
            }
            :global .react-multiple-carousel__arrow--left:after{
                left: 45px;
            }
            :global .react-multiple-carousel__arrow--right:after{
                right: 45px;
            }
        }
        
    }



     
     


       .hiddenMenuWrap{
        margin-bottom: 1%;
        .hideShowmenu{
      
        // --b:2px; /*border*/
        // --r:100px; /*radius*/
        // --p:65px; /*offset from top */
        // height:200px;
        // background:
        //    radial-gradient(circle var(--r)
        //      at 50% calc(-1*var(--p)), 
        //      transparent calc(100% - var(--b) - 1px), #e4e4e4 calc(100% - var(--b)), 
        //      #e4e4e4 100%)
        //     0 0/100% var(--b),
          
        //    radial-gradient(circle var(--r)
        //      at 50% calc(-1*var(--p)), 
        //      transparent calc(100% - var(--b) - 1px), #e4e4e4 calc(100% - var(--b)), 
        //      #e4e4e4 99%,transparent 100%);
        // background-repeat:no-repeat;








        --b:2px; /*border*/
        // --r:100px; /*radius*/
        // --p:65px; /*offset from top */

        --r: 35px;
        --p: 1px;
        height:200px;
        background:
        //    radial-gradient(circle var(--r)
        //      at 50% calc(-1*var(--p))
             
             
        radial-gradient(circle var(--r)
        at 50% calc(-1*var(--p))
        
             
             , 
             transparent calc(100% - var(--b) - 1px), #e4e4e4 calc(100% - var(--b)), 
             #e4e4e4 100%)
            0 0/100% var(--b),
          
            radial-gradient(circle var(--r)
             at 50% calc(-1*var(--p))
             , 
             transparent calc(100% - var(--b) - 1px), #e4e4e4 calc(100% - var(--b)), 
             #e4e4e4 99%,transparent 100%);
        background-repeat:no-repeat;




                   
            }

            .hideShowmenuBTN{

                width: 48px;
                height: 48px;
                background-color: #45046C;
                margin: auto;
                border-radius: 50%;
                position: relative;
                margin-top: -221px;
                cursor: pointer;
                z-index: 1;
                &:after,&:before{
                    content: "";
                    position:absolute;
                    width: 58px;
                    height: 28px;
                    background-color:#fff;
                    left: 50%;
                    transform: translateX(-50%);
                    margin: auto;
                    background-color:transparent;
                }
                &::before{
                    top: -6px;
                    border-top-left-radius: 58px;
                    border-top-right-radius: 58px;
                    border: 6px solid #fff;
                    border-bottom: 0;
                    z-index: 0;
                }
                &:after{
                    z-index: -1;
                    bottom: -6px;
                    border-bottom-left-radius: 58px;
                    border-bottom-right-radius: 58px;
                    border: 6px solid #fff;
                    border-top: 0;
                    box-shadow: 0px 4px 12px 2px rgba(0,0,0,0.15);
                    -webkit-box-shadow: 0px 4px 12px 2px rgba(0,0,0,0.15);
                    -moz-box-shadow: 0px 4px 12px 2px rgba(0,0,0,0.15);
                }
                .arrow {
                    border: solid 2px #fffdfa;
                    border-width: 0 4px 4px 0;
                    display: inline-block;
                    padding: 7px;
                    transform: rotate(225deg);
                    -webkit-transform: rotate(225deg);
                    left: 32%;
                    position: relative;
                    top: 17px;
                    
                }   
                &:hover{
                    
                    -webkit-animation-name: hvr-wobble-vertical;
                    animation-name: hvr-wobble-vertical;
                    -webkit-animation-duration: 1s;
                    animation-duration: 1s;
                    -webkit-animation-timing-function: ease-in-out;
                    animation-timing-function: ease-in-out;
                    -webkit-animation-iteration-count: 1;
                    animation-iteration-count: 1;
                    }

            }

       }    
}

.shimmer {
    margin: 10px 0;
}



.shimmer_items {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    margin: 0;
}

.showsmenu{
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // margin: auto;
    width: 100%;
    height: 0;
    transition: all .5s linear;
    box-shadow: 0px 4px 12px 2px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 4px 12px 2px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 4px 12px 2px rgba(0,0,0,0.15);

    .hideShowmenuBTN{
        .arrow{
            transition: all .5s  ease-in-out;
            transform: rotate(45deg) !important;
            top: 13px !important;
        }
        &::before{
            transition: all .5s  ease-in-out;
            border-color: transparent  !important;
        }  
    }

    li{
        
        overflow: hidden; 
        height: 0;
        transition: height .3s ease-in-out;
        -webkit-animation-name:slideUp;
        animation-name: slideUp;
        -webkit-animation-duration: .3s;
        animation-duration: .3s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        .item{
            img{
                transition: all .3s  ease-in-out;
                transform: scale(0,0) !important ;
                opacity: 0;
                
            }
        }
        
    }
   
    &.showButton{
        height: 130px;

        .hideShowmenuBTN{
            .arrow{
                transition: all .5s  ease-in-out;
                transform: rotate(225deg) !important;
                top: 17px !important;
            }
            &::before{
                border-color: #fff  !important;
            }
        }
        li{
            overflow: visible;
            height: 130px;
            -webkit-animation-name:slideDown;
            animation-name: slideDown;
            -webkit-animation-duration: .7s;
            animation-duration: .7s;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
            -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
            .item{
                &:hover{
                    img{
                        transform: scale(1.1,1.1)  translate(0, -5px) !important;
                    }
                }
                img{
                
                    transition: all 0.5s ease-in-out;
                    opacity: 1;
                    transform: scale(1,1) !important;
                }
            }
            
        
        }
      
    }
        
    .hideShowmenuBTN{
        margin-top: 0 !important;
    }
    .hiddenMenuWrap{
        position: absolute;
        bottom: -24px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

}
@-webkit-keyframes slideUp{
    0%{
        height: 130px;
        opacity: 1;
    }
    100%{
        height: 0;
        opacity: 0;
    }
}
@keyframes slideUp{
    0%{
        height: 130px;
        opacity: 1;
    }
    100%{
        height: 0;
        opacity: 0;
        
    }
}

@-webkit-keyframes slideDown{
    0%{
        height: 0;
        opacity: 0;
    }
    100%{
        height: 130px;
        opacity: 1;
        overflow: visible;
    }
}

@keyframes slideDown{
    0%{
        height: 0;
        opacity: 0;
    }
    100%{
        height: 130px;
        opacity: 1;
        overflow: visible;
    }
}

@keyframes zoomIn{
    0%{
        transform: scale(0,0);
    }
    100%{
        transform: scale(1,1);
    }
}
@-webkit-keyframes zoomOut{
    0%{
        transform: scale(1,1);
    }
    100%{
        transform: scale(0,0);
        
    }
}
@keyframes zoomOut{
    0%{
        transform: scale(1,1);
    }
    100%{
        transform: scale(0,0);
        
    }
}
@media screen and (max-width:1199px) {}

@media screen and (max-width:1080px) {}

@media screen and (max-width:991px) {}

@media screen and (max-width:767px) {
    .showsmenu {
        li{
            padding: 0 10px;
        }
        .item{
            padding: 10px 0;
            
            span{
                font-size: 14px;
                padding: 10px 15px 7px;
                &::after{
                    width: 12px;
                    height: 12px;
                }
            }
        }
        .carouselcontainer{
            &hover{
                button {
                    opacity: 1;
        
                }
                :global .react-multiple-carousel__arrow--right {
                    right: -32px !important;
                    height: 80px;
                    width: 80px;
                }
                :global .react-multiple-carousel__arrow--left {
                    left: -32px !important;
                    height: 80px;
                    width: 80px;
                }
                :global .react-multiple-carousel__arrow--left,:global .react-multiple-carousel__arrow--right{
                    top:32px
                }

            }
            
        }
    }
}

@media screen and (max-width:575px) {}

@media screen and (max-width:480px) {
    .shimmer :global .react-loading-skeleton {
        width: 60px !important;
        height: 60px !important;
    }
    .showsmenu {
        
        li{
            width: 110px !important;
        }
        .hiddenMenuWrap{
            margin-bottom: 3%;
        }
        
        .carouselcontainer{
            &:hover{

                button {
                    width: 70px;
                    height:70px;
                }
                :global .react-multiple-carousel__arrow--right {
                    right: -32px !important;
                    height: 70px;
                    width: 70px;
                }
                :global .react-multiple-carousel__arrow--left {
                    left: -32px !important;
                    height: 70px;
                    width: 70px;
                }
                :global .react-multiple-carousel__arrow--left,:global .react-multiple-carousel__arrow--right{
                    &:after{
                        top:30px;
                        border-width: 0 3px 3x 0;
                        padding: 4px;
                    }
                    
                }
                :global .react-multiple-carousel__arrow--right{
                    &::after{
                        right: 43px;
                    } 
                }
                :global .react-multiple-carousel__arrow--left{
                    &::after{
                        left: 43px;
                    }
                }
            }
           
        }
    }
}

@media screen and (max-height:600px) and (max-width:767px) {}

@media screen and (max-height:600px) and (max-width:575px) {}

@media screen and (max-height:400px) and (max-width:991px) {}

@media screen and (max-height:360px) and (max-width:991px) {}