.GalleryList{
    :global .container{
        position: relative;
    }
    h1{
        font-size: 37px;
        font-weight: 900;
        line-height: 1.3;
        letter-spacing: 1.2px;
        text-align: center;
        color: #162d3d;
        padding-top: 50px;
        padding-bottom: 45px;
        text-transform: uppercase;
        @media (max-width:1466px) {
            font-size: 32px;
        }
        @media (max-width:1199px) {
            padding-bottom: 6%;
        }
        @media (max-width:991px) {
            font-size: 30px;
            padding-top: 5%;
            padding-bottom: 6%;
        }
        @media (max-width:767px) {
            padding-top: 6%;
            padding-bottom: 6%;
        }
        @media (max-width:575px) {
            padding-top: 8%;
            padding-bottom: 8%;
        }
        @media (max-width:480px) {
            font-size: 26px;
            margin: 0px;
            padding-top: 30px;
            padding-bottom: 0;
            margin-bottom: 40px;
        }
        @media (max-width:375px) {
            font-size: 22px;
        }
        
    }
    .noData{
        display: block;
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 1.2px;
        color: #a7a4a7;
        padding: 5%;
    }
    .btn_back{
        background-color: #320150;
        color: #fff;
        border:0;
        box-shadow: none;
        border-radius: 4px;
        font-size: 15px;
        padding: 12px 20px;
        line-height: 14px;
        position: absolute;
        top: 55px;
        left: 15px;
        @media (max-width:991px) {
            top:5%;
        }
        @media (max-width:767px) {
            top: 4%;
        }
        @media (max-width:480px) {
            top: 24px;
            font-size: 14px;
            padding: 10px 14px;
        }
    }
}
