@import "../../../../styles/variables";

.main_wrapper {
  // width: 1920px;
  height: 80px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  width: 100%;
  z-index: 100;
  // position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100 !important;
  @include transition(all, 0.3s 0.5s, ease-in-out);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &.defaultHeader {
    left: 0;
    position: fixed !important;
    display: flex;
    top: 0;
    width: 100%;
    visibility: visible;
  }

  &.header_sticky {
    animation: header_stick 0.5s;
    left: 0;
    position: fixed !important;
    display: flex;
    top: 0;
    width: 100%;
    visibility: visible;
    @include transition(all, 8s 0.5s, ease-in-out);
  }

  &.headerSection {
    left: 0;
    position: relative !important;
    top: 0;
    width: 100%;
    z-index: 1001;
    visibility: hidden;
    display: none;
    @include transition(all, 0.3s 0.5s, ease-in-out);
  }

  @keyframes header_stick {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0);
    }
  }

  a {
    text-decoration: none;
  }

  .waffle_wrapper {
    background: #fff;
    box-shadow: 0px 23px 46px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 80px;
    left: 0px;
    z-index: 50;
    height: auto;
    max-width: 615px;
    padding: 20px 29px 29px 24px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    // transition: visibility .s ease;
    // @include transition(opacity, .3s, ease-in-out);

    &.show {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transform: translateY(0);
      // @include transition(opacity, .3s, ease-in-out);
    }

    &.hide {
      opacity: 0;
      visibility: hidden;
      // @include transition(opacity, .1s, ease-out);
    }

    span {
      color: rgba(0, 0, 0, 0.7);
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .iconWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 9px;

      .home_icon {
        width: 26px;
        height: 26px;
        flex-shrink: 0;
      }
    }

    .title {
      color: #000;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 0 0 13px;
      margin-bottom: 0rem;
    }

    .waffle_menu {
      display: grid;
      gap: 10px;
      grid-template-columns: auto auto auto auto;

      .waffle_child_wrapper {
        padding: 10px;
        width: 133px;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 168px;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.08);
        border: 1px solid transparent;

        &.active {
          border: 1px solid rgba(202, 150, 62, 0.4);
        }
        .child_container {
          // padding: 10px;
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 168px;

          .logo_box {
            font-size: 12px !important;
            color: #000 !important;
            font-weight: normal;
            text-align: center;

            figure {
              max-width: 100%;
              display: block;
              margin: auto;
              transition: none !important;
              margin: 0 auto 24px;

              img {
                height: 100%;
                display: block;
                margin: auto;
                transition: none !important;
              }
            }
          }
        }

        &:hover {
          width: 133px;
          border: 1px solid rgba(202, 150, 62, 0.4);
        }

        p {
          color: #323232;
          text-align: center;
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 0;
        }
      }

      a {
        text-decoration: none;
      }
    }
  }

  .logo_section {
    width: 336px;
    display: flex;
    align-items: center;
    background: rgba(48, 48, 48, 1);
    justify-content: center;
    column-gap: 33px;

    .waffle_logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      a img {
        width: 100%;
        height: 100%;
        max-height: 38.59px;
        object-fit: contain;
      }
    }

    .brand_logo {
      width: 126px;
      // height: 43px;
    }

    // @media screen and (max-width: 1440px) {
    //   min-width: none;
    //   gap: 10px;
    // }

    .waffleIcon {
      height: 38px;
      width: 38px;
      cursor: pointer;
      // padding-right: 20px;
      border-radius: 4px;
      border-right: 0.5px solid rgba(0, 0, 0, 0.4);
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.4);
      background: #3c3c3c;
      padding: 8px;
    }
  }

  .middle_section {
    display: inline-flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    padding: 0px 30px;
    width: 100%;
    background: linear-gradient(258deg, #87e0c5 -6.75%, #fec249 104.04%);

    // @media screen and (max-width:1440px) {
    //     padding-right: 0px;
    //     padding-left: 10px;
    // }

    .menu_section {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 100px;
      padding-right: 14px;
      height: 100%;
      width: 100%;

      @media screen and (max-width: 1640px) {
        padding-right: 20px;
        gap: 40px;
        padding-left: 10px;
      }

      @media screen and (max-width: 1440px) {
        padding-right: 20px;
        gap: 30px;
        padding-left: 10px;
      }

      @media screen and (max-width: 1240px) {
        padding-right: 20px;
        gap: 20px;
        padding-left: 10px;
      }

      img {
        height: 30px;
      }

      .middle_menu_wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 248px;
        width: 100%;
        height: 100%;

        @media screen and (max-width: 1680px) {
          gap: 150px;
        }

        @media screen and (max-width: 1440px) {
          gap: 100px;
        }

        @media screen and (max-width: 1240px) {
          gap: 50px;
        }

        ul {
          list-style-type: none;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          gap: 130px;
          margin-bottom: 0px;
          padding-left: 0rem;

          @media screen and (max-width: 1680px) {
            gap: 100px;
          }

          @media screen and (max-width: 1440px) {
            gap: 50px;
          }

          @media screen and (max-width: 1240px) {
            gap: 30px;
          }

          // @media screen and (max-width:1640px) {
          //     gap: 30px;
          // }

          // @media screen and (max-width:1240px) {
          //     gap: 20px;
          // }

          // @media screen and (max-width:1100px) {
          //     gap: 15px;
          // }
        }

        ul li {
          color: #fff;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;

          // @media screen and (max-width:1440px) {
          //     font-size: 18px;
          // }

          // @media screen and (max-width:1240px) {
          //     font-size: 16px;
          // }

          // @media screen and (max-width:1100px) {
          //     font-size: 15px;
          // }

          a {
            text-decoration: none;
            color: #2b2b2b;

            &:hover {
              color: white;
            }
          }

          .active {
            color: #303030;
            text-shadow: #303030;
          }
        }

        .search_wrap {
          cursor: pointer;
        }
      }

      .search_section {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        padding-left: 50px;

        // height: 20px;
        img {
          height: 30px;
          width: 30px;
          padding: 2px;
        }

        input {
          border: none;
          background-color: transparent;
          color: #fff;
          border-bottom: 1px solid white;
          transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
          width: 0;
          opacity: 0;

          &.show {
            width: 100%;
            opacity: 1;
            height: 50px;
            animation: show_search 0.8s forwards;
          }

          &.hide {
            width: 100%;
            opacity: 1;
            pointer-events: none;
            animation: hide_search 0.8s forwards;
          }
        }

        @keyframes show_search {
          from {
            width: 0;
            opacity: 0;
            right: 0;
          }

          to {
            width: 100%;
            opacity: 1;
          }
        }

        @keyframes hide_search {
          from {
            width: 100%;
            opacity: 1;
          }

          to {
            width: 0;
            opacity: 0;
            right: 0;
          }
        }

        input::placeholder {
          color: #fff;
        }

        .search_close_icon {
          background-color: transparent;
          border: none;
        }

        &.show {
          display: flex;
          width: 100%;
          @include transition(all, 0.8s, ease-in-out);
        }

        &.hide {
          display: none;
          gap: 8px;
          width: 100%;
          visibility: visible;
          pointer-events: all;
          @include transition(all, ease-in-out, 0.3s);
        }
      }

      .live_img {
        width: 100px;
        height: 40px;
        flex-shrink: 0;
        cursor: pointer;
      }

      .render_menu_ul {
        height: 100%;

        .search_img {
          cursor: pointer;

          @media screen and (max-width: 1440px) {
            height: 20px;
            width: 20px;
          }
        }
      }
    }

    .more_button {
      color: #2b2b2b;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      line-height: normal;
      text-transform: capitalize;
      cursor: pointer;
      position: relative;
      z-index: 9999;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        &:hover {
          color: white;
        }
      }
      ul {
        gap: 0px;
      }

      @media screen and (max-width: 1440px) {
        font-size: 18px;
      }

      .dropdown_label {
        color: #2b2b2b;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        display: flex;
        justify-content: center;
      }

      .dropdown_menu {
        background: #f3f3f3;
        display: none !important;
        gap: 0px !important;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        box-shadow: 4px 4px 12px 5px rgba(0, 0, 0, 0.1);
        width: 162px;
        height: auto;
        flex-shrink: 0;
        position: absolute;
        top: 80px;
        left: 0;

        &.last-child {
          padding: 10px auto 15px 25px;
        }
        // z-index: 9999;

        .child_nav_link {
          color: #303030;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 12px 0px 12px 25px;
          text-align: left;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          width: 100%;
          text-transform: capitalize;

          @media screen and (max-width: 1440px) {
            font-size: 18px;
          }

          @media screen and (max-width: 1240px) {
            font-size: 16px;
          }

          a {
            text-decoration: none;
            color: #303030;

            &:hover {
              color: #303030;
            }
          }

          // .active {
          //     color: white;
          //     background-color: #303030;
          //     text-shadow: #303030;
          // }
        }
        .child_nav_link &.last-child {
          padding: 10px auto 15px 25px;
        }
      }
    }

    .more_button:hover .dropdown_menu {
      display: flex !important;
      color: #fff;
    }
  }

  .donate {
    display: flex;
    width: 205px;
    height: 80px;
    padding: 15px 37px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background: #fcb122;
    color: #323232;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    &:hover {
      background-color: #303030;
      color: #fff;
    }

    @media screen and (max-width: 1440px) {
      font-size: 18px;
      width: 150px;
    }

    @media screen and (max-width: 1240px) {
      font-size: 18px;
      width: 150px;
    }

    @media screen and (max-width: 1100px) {
      font-size: 18px;
      width: 120px;
    }
  }
}
