@import '../../styles/animation.scss';
.UploadCrafts{
    background-color: #28013F;
    padding-bottom: 80px;

    img{
        margin: 0 auto;
        display: block;
        padding-bottom: 10% ;

        &.galleryImg{
            margin-bottom: 11px ;
        }
    }
    .button{
        padding: 15px;
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 10px;
        font-size: 22px;
        font-weight: 600;
    }
    p{
        color: #BDA1D1;
        text-align: center;
        margin: 30px 80px;
    }
    :global .container{
        max-width: 860px !important;
        .row{
            justify-content: center;
        }
    }
    .col{
        &:hover{
            img{
                -webkit-animation-name: buzz-out;
                animation-name: buzz-out;
                -webkit-animation-duration: .7s;
                animation-duration: .7s;
                -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
                -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
            }
        }
    }
}
@media screen and (max-width:767px) {
    .UploadCrafts{
        padding-bottom: 60px;
        .button{
            font-size: 20px ;
        }
    }
}

@media screen and (max-width:480px) {
    .UploadCrafts{
        padding-bottom: 40px;
        .container{
            padding: 0;
            .row{
                margin: 0;
            }
        }
        .button{
            font-size: 18px ;
        }
    }
}
@media screen and (max-width:360px) {
    .UploadCrafts{
        .button{
            font-size: 17px;
        }
    }
}
