@import '../../styles/animation.scss';

.GalleryThumb{

    margin-bottom: 30px;
    cursor: pointer;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    border-radius:10px;
    :global .card{
        border-radius:10px;
        border:0;
    }
    &:hover{
        :global .card{
            -webkit-animation-name: hvr-wobble-vertical;
            animation-name: hvr-wobble-vertical;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
            -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
        }
        
        .image{
            img{
                transform: scale(1.2);
            }
        }
        
    }
        
    .image{
        width: 100%;
        display: block;
        max-width: 100%;
        overflow: hidden;
        max-height: 213px;
        border-top-left-radius:10px;
        border-top-right-radius:10px;
        img{
            transform: scale(1);
            object-fit: cover;
            height: 213px;
            -webkit-transition: all .4s ease-in-out;
            -moz-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
            border-top-left-radius:10px;
            border-top-right-radius:10px;
        }
    }
    
    .flag{
        display: inline-block;
        margin-right: 12px;
        vertical-align: top;
    }
    :global .card-title{
        margin-bottom: 0;
        font-size: 15px;
        font-weight:600 ;
        display: flex;
        align-items: center;
        
        strong{
            display: inline-block;
            text-overflow: ellipsis;
            font-weight:600 ;
            white-space: nowrap;
            overflow: hidden;
            vertical-align: top;
            max-width: 126px;
            padding-right: 5px;
        }
        span{
            padding-left: 5px;
            display: inline-block;
        }
        
    }
    :global .card-body {
        border-radius: 4px 4px 10px 10px;
        box-shadow: 0px 14px 88px #00000017;
        // min-height: 90px;
        // display: flex;
        // align-items: center;
    }
}
@media screen and (max-width:1199px){
    .GalleryThumb{
        :global .card-title{
            strong{
                max-width: 164px;
            }
        }
    }
}
@media screen and (max-width:991px){
    .GalleryThumb{
        :global .card-title{
            strong{
                max-width: 202px;
            }
        }
    }
}

@media screen and (max-width:768px){
    .GalleryThumb{
        :global .card-title{
            font-size: 14px;
            strong{
                max-width: 126px;
            }
        }
        :global .card-body {
            padding: 15px;
        }
    }
}

@media screen and (max-width:575px){
    .GalleryThumb{
        :global .card-title{
            // font-size: 17px;
            strong{
                max-width: 80%;
            }
        }
    }
}

@media screen and (max-width:480px){
    .GalleryThumb{
        :global .card-title{
            // font-size: 17px;
            strong{
                max-width: 73%;
            }
        }
    }
}

@media screen and (max-width:370px){
    .GalleryThumb{
        :global .card-title{
            // font-size: 17px;
            strong{
                max-width: 70%;
            }
        }
    }
}
