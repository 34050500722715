@import '../../styles/_colors.scss';

.topMenu {
    background-color: $pmColor;
    position: relative;
    z-index: 1;
    .topMenuSm {
        display: none;
        left: -87px;
        top: 60px;
        .searchIcons{
            i{
                cursor:pointer;
            }
        }
        .navDropdown {
            position: relative;
            
                :global .dropdown-toggle{
                    transition:all .4s ease-in-out;
                    &:hover{
                        transform: rotate(180deg);
                    }
                    &:after{
                        display: none !important;
                    }
                }                
                :global .dropdown-menu{
                    min-width: 290px;
                    border-radius: 4px;
                    border:0;
                    padding: 20px 30px 10px;
                    text-align: center;
                    top: 117%;
                    right: 0 !important;
                    left: inherit;
                    box-shadow: 0px 3px 6px #00668440;
                }
            
            &:hover{
                &::after{
                    content: "";
                    position: absolute;
                    top:99%;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 11;
                }
                .dropdown-menu{
                    display: block !important;
                }
                svg{
                    transform: rotate(-40deg);
                    -webkit-transform: rotate(-40deg);
                }
            }
            .logo_grid{
                justify-content: space-between;

                .logo_list{
                    padding: 0;
                    background: #fff;
                    border-radius:0;
                    transition:all .4s ease-in-out;
                    overflow:hidden;
                    min-height: 115px;
                
                    a{
                        display: block;
                        padding: 10px;
                        transition:all .4s ease-in-out;
                    }
                    &.disable{
                        pointer-events: none;
                        opacity: 0.8;
                        a{
                            pointer-events: none; 
                        }
                    }
                    &.active{
                        position: relative;
                    }
                    &:hover{
                        border-radius:6px;
                        background-color: #efefef !important;
                        a{
                            border-radius:6px;
                            background-color: #efefef !important;
                        }
                        .logo_box{
                            background-color: #efefef !important;
                        }
                    }
                    .logo_box{ 
                        width: 100%;
                        margin: auto;
                        transition:all .4s ease-in-out;
                        
                        figure{
                            margin:0 auto 15px;
                            text-align: center;
                            img{
                                transition: none !important;
                                max-width: 100%;
                                max-height: 48px;
                                width: auto !important;
                                display: block;
                                margin: auto;
                            }
                        }
                        h5 {
                            font-size: 12px !important;
                            color: #000 !important;
                            line-height: 13px;
                            font-weight: 500;
                            max-width: 48px;
                            width: 100%;
                            margin: auto;
                            text-transform: capitalize;
                        }
                        
                    }
                }
            }   
        }
    }
    .topMenuLg {
        height: 60px;
        padding: 8px 0 6px 0;
        .menucontentbox {
            display: flex;
            align-items: center;
            width: 100%;
            .main_menu_wrap{
                display:flex;
                justify-content: space-between;
                padding-right: 70px;

                width:100%;
                &.false{
                    transition:all .4s ease-in-out;
                }
                &.true{
                    transition:all .4s ease-in-out;
                }
                a{
                    margin:0 5px;
                    white-space: nowrap;
                    &:first-child{
                        padding-left:0;
                    }
                    &:last-child{
                        padding-right:0;
                    }
                }
            }
            .searchIcons{
                cursor:pointer;
                i{
                    cursor:pointer;
                }
            }
            .donateMenu{
                display:flex;
                align-items:center;
                justify-content:center;
                text-transform:capitalize;
                color:#fff;
                .donateLink{
                    font-size: 17px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.82;
                    letter-spacing: 0.2px;
                    text-align: left;
                    text-decoration: none;
                    text-transform: capitalize;
                    color: #ffffff;
                    display: inline-block;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-image: linear-gradient(to right, #ffb800, #ffb800 50%, #fff 50%);
                    background-size: 200% 100%;
                    background-position: 100%;
                    &:hover{
                        transition: all 0.4s cubic-bezier(0, 0, 0.23, 1);
                        background-position: 0;
                    }
                }
            }
            .loginMyshalom{
                padding:0 58px 0 12px;
                border:1px solid #621296;
                border-radius: 10px;
                display: flex;
                align-items: center;
                max-width: 134px;
                height: 47px;
                width: 100%;
                position: relative;
                .profileName{
                    color: #fff;
                    .name{
                        font-size: 9px;
                    }
                    .myshalom{
                        font-size: 12px;
                    }
                    span,.myshalom{
                        display: block;
                        color: #fff;
                    }
                }
                .profileImg{
                    position: absolute;
                    width: 30px;
                    height: 24px;
                    top:0;
                    bottom: 0;
                    right: 12px;
                    margin: auto;

                }
            }
            .navDropdown {
                margin-left: 30px;
                cursor: pointer;
                position: relative;
                
                :global .dropdown-toggle{
                    transition:all .4s ease-in-out;
                    &:hover{
                        transform: rotate(180deg);
                    }
                } 
                              
                :global .dropdown-menu{
                    min-width: 290px;
                    border-radius: 4px;
                    border:0;
                    padding: 20px 30px 10px;
                    text-align: center;
                    top: 140%;
                    box-shadow: 0px 3px 6px #00668440;
                }
                
                // &.show{
                //     .dropdown-menu{
                //         position: relative;
                //         &:after{
                //             content: " ";
                //             position: absolute;
                //             border-style: solid;
                //             width: 0;
                //             height: 0;
                //             border-width: 0 20px 20px 20px;
                //             border-color: transparent transparent rgba(0,0,0,.15) transparent;
                //             top: -20px;
                //             left: 23%;
                //         }
                        
                //     }
                // }
                &:hover{
                    &::after{
                        content: "";
                        position: absolute;
                        top:99%;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 11;
                    }
                    :global .dropdown-menu{
                        display: block !important;
                    }
                    svg{
                        transform: rotate(-40deg);
                        -webkit-transform: rotate(-40deg);
                    }
                }
                .logo_grid{
                    justify-content: space-between;

                    .logo_list{
                        padding: 0;
                        background: #fff;
                        border-radius:0;
                        transition:all .4s ease-in-out;
                        overflow:hidden;
                        min-height: 115px;
                        a{
                            display: block;
                            padding: 10px;
                            transition:all .4s ease-in-out;
                           
                        }
                        &.disable{
                            pointer-events: none;
                            opacity: 0.8;
                            a{
                                pointer-events: none; 
                            }
                        }
                        &.active{
                            position: relative;
                        }
                        &:hover{
                            border-radius:6px;
                            background-color: #efefef !important;
                            a{
                                border-radius:6px;
                                background-color: #efefef !important;
                            }
                            .logo_box{
                                background-color: #efefef !important;
                            }
                        }
                        .logo_box{ 
                            width: 100%;
                            margin: auto;
                            transition:all .4s ease-in-out;
                            figure{
                                margin:0 auto 15px;
                                text-align: center;
                                img{
                                    transition: none !important;
                                    max-width: 100%;
                                    max-height: 48px;
                                    width: auto !important;
                                    display: block;
                                    margin: auto;
                                }
                            }
                            
                        }
                    }
                }
    
                hr{
                    margin: 0;
                }
                svg {
                    max-height: 48px;
                    transition: all .3s ease-in-out;
                }
                a {
                    font-size: 12px !important;
                    color: #000 !important;
                    font-weight: normal;
                    
                    h5{
                        font-size: 12px !important;
                        color: #000 !important;
                        line-height: 13px;
                        font-weight: 500;
                        max-width: 48px;
                        width: 100%;
                        margin: auto;
                    }
                    
                    &::after {
                        content: "";
                        display: none;
                    }
                }
                

            }


            :global .dropdown-menu {
                right: -38px;
                left:inherit;
                top: 190%;
                border-radius: 4%;
                padding: 5px 23px ;
                min-width: 176px;
                background-color: white !important;
                
                
                a{
                    transition: all .4s ease-in-out;
                    background-color: #fff !important;
                    text-decoration: none;
                    

                }
                a:hover{
                    color: #320150  !important;
                    
                }
            }
            :global .dropdown-item.active {
                background-color: whitesmoke !important;
            }
           


            :global .dropdown-menu {
                &:before{
                    content:" ";
                    position: absolute;
                    border-style: solid;
                    border-width: 0 20px 20px 20px;
                    border-color: transparent transparent #ffffff transparent;
                    top: -20px;
                    right: 17%;
                    left:inherit;
                    z-index: 1;
                    display: none;
                }
                &:after{
                    content: " ";
                    position: absolute;
                    border-style: solid;
                    border-width: 0 22px 20px 22px;
                    border-color: transparent transparent rgba(0, 0, 0, 0.15) transparent;
                    top: -20px;
                    right: 17%;
                    z-index: 0;
                    display: none;
                }
            }
            .menulink {
                color: $pmFontcolor;
                font-size: 17px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.82;
                letter-spacing: 0.2px;
                text-align: left;
                text-decoration: none;
                text-transform: capitalize;
                color: #ffffff;
                display: inline-block;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-image: -webkit-gradient(linear, left top, right top, from(#ffb800), color-stop(50%, #ffb800), color-stop(50%, #fff));
                background-image: linear-gradient(to right, #ffb800, #ffb800 50%, #fff 50%);
                background-size: 200% 100%;
                background-position: 100%;
                &:hover{
                    -webkit-transition: all 0.4s cubic-bezier(0, 0, .23, 1);
                    transition: all 0.4s cubic-bezier(0, 0, .23, 1);
                    background-position: 0;
                }
                &.active {
                    color: #ffb800;
                }
                &.after {
                    color: #ffffff; 
                }
            }
            
            .swprayerImg {
                width: 109px;
            }
        }
    }
    .navDropdown1 {
        position: absolute !important;
        margin-left: -8% !important;
    }
    .logos {
        width: 209px;
        text-align: center;
        // background-color: white !important;
    }
    .logos:active {
        width: 209px;
       
        // margin-top: -9px !important;
        // text-align: center;
        // margin-bottom: -10px !important;
        // margin-top: 4px !important;
        // text-align: center;
        // margin-bottom: -5px !important;
        background-color: rgb(219, 212, 212) !important;
    }
    .logos:hover {
        background-color:  rgb(219, 212, 212) !important;}
    .imgPadding {
        width: 109px;
        height: 70px;
    }
}
body.mobMenu .topMenuSm{
    z-index: 1000000 !important;
}
.showAnim{
    :global .dropdown-toggle{
        transform: rotate(180deg);
    }
   
}
:global{
    .search-opened{
        .header-nav{
            z-index: 2 !important;
        }
    }
}

@media screen and (max-width:1199px) {}

@media screen and (max-width:1080px) {}

@media screen and (max-width:991px) {
    .topMenuLg {
        padding: 0 !important;
    }
    .topMenu {
       
        .topMenuLg {
            .menucontentbox {
                .navDropdown {
                    right: 0;
                    
                }
                .main_menu_wrap{
                    padding-right: 30px;
                }
            }
        }
    }
}


@media screen and (max-width:767px) {
    .topMenu {
        .topMenuLg {
            display: none;
        }
        .topMenuSm {
            color: #162d3d !important;
            border-color: none !important;
            outline: none !important;
            min-height: 75px;
            background-color: $pmFontcolor !important;
            display: block;
            padding: .5rem 0 0;
            
            .menuToggleSM {
                margin-top: 12px;
                outline: none;
                border: none;
            }
            .menucontentbox {
                width: 100%;
                .menulink {
                    padding: 4% !important;
                    color: #162d3d !important;
                    text-decoration: none;
                }
                .menulink:hover {
                    background-color: aquamarine;
                }
            }
            .menucontentbox a:hover {
                background-color: #9b62be !important;
            }
            .menucontentbox a:active {
                background-color: #cabdd2 !important;
            }
        }
    }
    .topMenuSm {
        top: inherit !important;
        left: inherit !important;
        .navDropdown {
            cursor: pointer;
            position: relative;
            :global .dropdown-toggle{
                transition:all .4s ease-in-out;
                transform: rotate(0deg) !important;
            } 
            &.showAnim{
                :global .dropdown-toggle{
                    transform: rotate(180deg) !important;
                }
               
            }  
        }     
    }
    
    
    
        
}

@media screen and (max-width:575px) {
    .topMenu {
        .topMenuSm {
            .navDropdown {
                :global .dropdown-menu{
                    min-width: 270px;
                    padding: 20px 25px 10px;
                    top: 120%;
                }
                .logo_grid{
    
                    .logo_list{

                        .logo_box{
                            figure{
                                margin-bottom: 12px;
                                img{
                                    transform: scale(0.9);
                                }
                            }
                        }
                    }
                }
            }     
        }
    }

}

@media screen and (max-width:480px) {}

@media screen and (max-height:600px) and (max-width:767px) {}

@media screen and (max-height:600px) and (max-width:575px) {}

@media screen and (max-height:400px) and (max-width:991px) {}

@media screen and (max-height:360px) and (max-width:991px) {}
