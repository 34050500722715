
.modalContainer{
    // margin-top: 4%;  
    display: flex !important;
    justify-content: center;
    align-items: center; 
    :global .modal-dialog{
        // max-width: 1100px !important;
        max-width: 95% !important;
        max-height: 95%;
        width: auto !important;
        display: inline-block;
    }
    :global  .modal-content{
        border-radius: 10px;
    }
    .modalClose{
        position: absolute;
        top: 15px;
        right: 15px;
        width: 35px;
        height: 35px;
        cursor: pointer;
        color: #000000;
        border-radius: 100%;
        background-color: #fff;
        font-size: 20px;
        text-align: center;
        line-height: 35px;
        box-shadow: 0px 3px 6px rgba(0,0,0,0.15);
        -webkit-box-shadow: 0px 3px 6px rgba(0,0,0,0.15);
        -moz-box-shadow: 0px 3px 6px rgba(0,0,0,0.15);
        i{
            display: inline-block;
            font-style: normal;
            -webkit-transition: all 0.4s ease-in-out;
            -moz-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
        }
        &:hover{
            i{
                transform: rotate(360deg);
            }
            
        }
    }

    .modalWrap{
        border-radius: 60px !important;
        background-color: #ffffff !important;
        :global  .modal-body{
            text-align: center;
            padding: 27px;
            border-radius: 10px;
            position: relative;

        }
        
        
        img{
            max-width: 100%;
            max-height: 90vh;
            border-radius: 10px;
        }
        .imageSlider{
            :global .carousel .slide img{
                height: 400px !important;
                object-fit: contain !important;
            }
            :global .carousel .slider-wrapper.axis-horizontal .slider .slide{
                border-radius: 12px !important;
            }

            :global .carousel .thumb {
                width: 160px !important;
                height: 86px !important;
                border-radius: 12px !important;
                display: inline-flex !important;
                object-fit: contain !important;
                padding: 0px !important;
                background-color: #000000;
            }

            :glabal .thumb{
                object-fit: fix !important;
            }
            :global .carousel .thumbs-wrapper {
                overflow-x: scroll !important;
            }
            :global .carousel .thumb img {
                object-fit: contain !important;
            }

            ::-webkit-scrollbar {
                display: auto !important;
            }

            :global .carousel .carousel-status {
                display: none !important;
            }

            :global  .carousel.carousel-slider .control-arrow {
               
                width: 60px;
                height: 60px;
                margin: -5% !important;
                margin-top: 0;
                margin-top: 25% !important;
                background: white !important;
                 padding: 0px !important;
                 z-index: 1000;
            }

            :global .carousel.carousel-slider{
                overflow: visible !important;
            }


            :global .carousel.carousel-slider .control-arrow:before {
                margin: 0 5px;
                display: inline-block;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                content: '';
                color: #320150;
            }



            :global .carousel .thumbs-wrapper {
                margin: 0px !important;
                margin-top: 14px !important;
                margin-bottom: -11px !important;
            }


            :global .carousel.carousel-slider .control-arrow{
                opacity: unset !important;
                border-radius: 47% !important;
                margin-top: 22% !important;
            }

            :global .carousel .control-next.control-arrow:before {
                // border-left: 8px solid black;

                // border-left: 13px solid black;
                border: solid #320150 !important;
                border-width: 0 4px 4px 0 !important;
                display: inline-block !important;
                padding: 6px !important;
                transform: rotate(-45deg) !important;
                -webkit-transform: rotate(-45deg) !important;
                margin-left: 1px !important;
                z-index: 1000 !important;

            }
            :global .carousel .control-prev.control-arrow:before {
                border: solid #320150 !important;
                border-width: 0 4px 4px 0 !important;
                display: inline-block !important;
                padding: 6px !important;
                transform: rotate(-45deg) !important;
                -webkit-transform: rotate(-45deg) !important;
                transform: rotate(135deg) !important;
                -webkit-transform: rotate(135deg) !important;
                margin-left: 9px !important;
                z-index: 1000 !important;
            }

            :global .carousel.carousel-slider {
                button{
                    position: absolute !important;
                }
            }


            ::-webkit-scrollbar {
                width: 90%;
                height: 5px;
                margin-top: 5px;
              }
              ::-webkit-scrollbar-track {
                // box-shadow: inset 0 0 5px grey; 
                border-radius: 10px;
              }
              ::-webkit-scrollbar-thumb {
                background: #b4acac;
                border-radius: 13px;
              }

              ::-webkit-scrollbar-thumb:hover {
                background:gray; 
              }


        }




}


}


@media screen and (max-width:480px) {
   
    
    .modalContainer{
        margin-top: 8%;   
        .modalWrap{
            .imageSlider{
                :global .carousel.carousel-slider .control-arrow{
                    margin-top: 35% !important;
            
                } 

                :global  .carousel.carousel-slider .control-arrow {
                    width: 35px !important;
                    height: 50px !important;
                }
                :global .carousel .slide img{
                    height: 250px !important;
                }
        }
    }
}
}