@import "../../styles/_colors.scss";
.ShowDetailBanner {
  position: relative;
  min-height: 460px;
  max-height: 460px;
  &:after {
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 0;
    top: 0;
    left: 0;
    /* 16:9 = 56.25% = calc(9 / 16 * 100%) */
    // padding-bottom: 30%;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }
  .btnViolet {
    position: relative;
  }
  .readCheck {
    position: relative;
  }
  .title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    left: 10%;
    color: $pmFontcolor;
    z-index: 1;
    width: 100%;
    h2 {
      //max-width: 301px;
      margin: 0 60px 14px 0;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.11);
      // font-family: Nexa;
      font-size: 41px;
      font-weight: 800;
      line-height: 50px;
      letter-spacing: 1.2px;
      text-align: left;
      color: #ffffff;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-width: 400px;
      width: 100%;
    }
    p {
      max-width: 370px;
      //height: 84px;
      margin: 14px 0 31px;
      // font-family: Nexa;
      font-size: 15px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 1.2px;
      text-align: left;
      color: #ffffff;
      //display: -webkit-box;
      //  -webkit-line-clamp: 4;
      //-webkit-box-orient: vertical;
      //overflow: hidden;
    }
    .des_readmore p {
      //line-clamp: 4;
      // -webkit-line-clamp: 4;
    }
    .des_readless p {
      //line-clamp: initial;
      // -webkit-line-clamp: initial;
    }
    .scheduleTime {
      color: #ffb800;
      font-weight: 500;
      display: inline-block;
    }
    .readMore {
      font-size: 15px;
      font-weight: 500;
      color: #ffb800;
      text-decoration: none;
      position: relative;
      top: 0;
      background: transparent;
      box-shadow: none;
      border: 0;
      padding: 0 12px;
      line-height: 34px;
      height: 34px;
      border-radius: 4px;
      display: inline-block;
      cursor: pointer;

      .arrow {
        border: solid 2px #ffa700;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        position: relative;
        left: 0;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transition: all ease-in-out 0.4s;
        position: relative;
      }
      &:hover {
        i {
          left: 6px;
        }
      }
    }
    .description {
      .showMore {
        font-size: 15px;
        font-weight: 500;
        line-height: 26px;
        cursor: pointer;
        color: #ffb800;
        text-decoration: none;
        position: relative;
        background: transparent;
        margin-left: 5px;
        display: inline-block;
      }
    }
    p {
      max-width: 370px;
      //height: 84px;
      margin: 14px 0 31px;
      // font-family: Nexa;
      font-size: 15px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 1.2px;
      text-align: left;
      color: #ffffff;
    }
    .description {
    }
    .btnWrap {
      display: flex;
      flex-wrap: wrap;
      // margin-top: -42px !important;
      :global {
        .btn {
          max-width: 171px;
          width: 100%;
          height: 52px;
          margin: 10px 8px 10px;
          border-radius: 5px;
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.11);
          background-color: $pmColor;
          font-size: 21px;
          font-weight: 900;
          line-height: 52px;
          letter-spacing: 1.2px;
          text-align: center;
          padding: 0px 15px;
          border: none;
          transition: all 0.3s ease-in-out;
          display: flex;
          justify-content: center;
          align-items: center;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
        .filter_ddl {
          position: relative;
          display: inline-block;
          margin-left: 8px;
          .dropdown {
            button {
              color: #ffffff;
              background-color: #320150;
              max-width: inherit;
              width: auto !important;
              &:focus,
              &:active {
                background-color: #320150 !important;
              }
            }
            .dropdown-menu {
              width: 100%;
            }
            .dropdown-item {
              &.active,
              &:active,
              &:hover {
                background-color: #e4e7ea;
              }
            }
          }
          //   &::after {
          //     content: "";
          //     position: absolute;
          //     top: 0;
          //     right: 15px;
          //     bottom: 0;
          //     margin: auto;
          //     width: 0;
          //     height: 0;
          //     border-left: 5px solid transparent;
          //     border-right: 5px solid transparent;
          //     // border-top: 6px solid #320150;
          //   }
          .seasonal_ddl {
            width: auto;
            height: 52px;
            margin: 10px 8px 10px;
            border-radius: 5px;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.11);
            background-color: #ffffff;
            color: #320150;
            font-size: 21px;
            font-weight: 900;
            line-height: 1;
            letter-spacing: 1.2px;
            border: none;
            transition: all 0.3s ease-in-out;
            padding-right: 25px !important;
            appearance: none;
            position: relative;
          }
        }
      }
      .promobtn {
        color: #ffffff;
        background-color: #320150;
        &:hover {
          background-color: #ffffff;
          color: #320150;
        }
      }
      .gallerybtn,
      .uploadbtn {
        background-color: #ffffff;
        color: #320150;
        svg {
          margin-right: 10px;
        }
        &:hover {
          color: #fff;
          background-color: #320150;
          svg {
            fill: #fff;
            * {
              fill: #fff;
            }
          }
        }
        p {
          max-width: 370px;
          //height: 84px;
          margin: 14px 0 31px;
          // font-family: Nexa;
          font-size: 15px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 1.2px;
          text-align: left;
          color: #ffffff;
        }
        .description {
        }
        .btnWrap {
          display: flex;
          // margin-top: -42px !important;
          :global {
            .btn {
              max-width: 171px;
              width: 100%;
              height: 52px;
              margin: 10px 8px 10px;
              border-radius: 5px;
              box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.11);
              background-color: $pmColor;
              font-size: 21px;
              font-weight: 900;
              line-height: 52px;
              letter-spacing: 1.2px;
              text-align: center;
              padding: 0px 15px;
              border: none;
              transition: all 0.3s ease-in-out;
              &:first-child {
                margin-left: 0;
              }
              &:last-child {
                margin-right: 0;
              }
            }
            .filter_ddl {
              position: relative;
              display: inline-block;
              button {
                max-width: inherit;
                color: #fff;
              }
              .seasonal_ddl {
                width: auto;
                height: 52px;
                margin: 10px 8px 10px;
                border-radius: 5px;
                box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.11);
                background-color: #ffffff;
                color: #320150;
                font-size: 21px;
                font-weight: 900;
                line-height: 1;
                letter-spacing: 1.2px;
                border: none;
                transition: all 0.3s ease-in-out;
                padding-right: 25px !important;
                appearance: none;
                position: relative;
              }
            }
          }
          .promobtn {
            color: #ffffff;
            background-color: #320150;
            &:hover {
              background-color: #ffffff;
              color: #320150;
            }
          }
          .gallerybtn,
          .uploadbtn {
            background-color: #ffffff;
            color: #320150;

            &:hover {
              color: #fff;
              background-color: #320150;
              svg {
                fill: #fff;
                * {
                  fill: #fff;
                }
              }
            }
          }
          .uploadbtn {
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1466px) {
  .ShowDetailBanner {
    .title {
      h2 {
        font-size: 31px;
      }
      .btnWrap {
        :global {
          .btn {
            height: 46px;
            font-size: 18px;
            font-weight: 900;
            line-height: 46px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1199px) {
}

@media screen and (max-width: 1080px) {
  .ShowDetailBanner {
    min-height: 420px;
    max-height: 420px;
  }
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
  .ShowDetailBanner {
    min-height: 400px;
    max-height: 400px;
    .title {
      h2 {
        font-size: 26px;
      }
      p {
        margin: 10px 0 20px;
      }
      .btnWrap {
        :global {
          .btn {
            font-size: 17px;
            font-weight: 800;
            width: 160px;
          }
        }
        .uploadbtn {
          svg {
            margin-right: 7px;
            flex: 0 0 17px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .ShowDetailBanner {
    min-height: 400px;
    max-height: 400px;
    .title {
      left: 0;
      padding: 0 15px;
      h2 {
        font-size: 26px;
      }
      p {
        margin: 8px 0 15px;
      }
      .btnWrap {
        margin-top: 0 !important;
        :global {
          .btn {
            padding: 0 12px;
            font-size: 16px;
            margin: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .ShowDetailBanner {
    position: relative;
    min-height: 360px;
    max-height: 360px;
    img {
      width: 100% !important;
      overflow-y: hidden;
    }
    &:after {
      padding-bottom: 60%;
    }
    .title {
      .description {
      }
      .btnWrap {
        :global {
          .btn {
            max-width: 126px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .ShowDetailBanner {
    .title {
      .btnWrap {
        :global {
          .btn {
            font-size: 14px;
            padding: 0 10px;
            height: 40px;
            line-height: 40px;
            width: auto;
            min-width: 100px;
            margin: 10px 10px 0 0;
            svg {
              flex: 0 0 15px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-height: 600px) and (max-width: 767px) {
}

@media screen and (max-height: 600px) and (max-width: 575px) {
}

@media screen and (max-height: 400px) and (max-width: 991px) {
}

@media screen and (max-height: 360px) and (max-width: 991px) {
}
