@import '../../styles/_colors.scss';
.ScheduleDateList {
    margin-top: 9%;

    .title{
        text-align: center;
        h1{
          font-size: 30px;
          line-height: 34px;
          font-weight: 900;
        }
    }
    .watchLivebtn{
        text-align: right;
        margin-top: -6%;
        margin-bottom: 7%;
        .watchbtn{
            max-width: 240px;
            height: 61px;
            border-radius: 5px;
            background-color: $pmColor;
            font-size: 22px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.18;
            letter-spacing: 1.2px;
            text-align: center;
            color: $pmFontcolor;
            outline: none;
            border: 0;
            width: 190px;

           a{
            color: white !important;
            text-decoration: none !important;
           
           }
        }
    
    }
   .dateContainer{
    border-right: solid 1px #e4e4e4;
    text-align: center;
    transition: all .3s ease-out;
    .day{
      cursor: pointer;
    }
    .date{
      cursor: pointer;
    }

   } 

    .dateContainer:hover {
        transform: translate(0, -4px);
    }
    .dateContainer:hover {
      transform: translate(0, -4px);
      .day{
        color: #937ca3
      }
    }

   .dateContainerLast{
    text-align: center;
   } 
   .day{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 1.2px;
    color: #a7a4a7;
    cursor: pointer;
   }
   .date{
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: normal;
    color: #320150;
    cursor: pointer;
   }
}

.dateContainerLast:hover {
  transform: translate(0, -4px);
}
.dateContainerLast:hover {
transform: translate(0, -4px);
.day{
  color: #937ca3
}
}
.nodata{
  text-align: center;
  padding: 7%;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 1.2px;
  color: #a7a4a7;
}

@media screen and (max-width:991px) {
  .ScheduleDateList{
    .title{
        text-align: left;
    }
    .watchLivebtn{
      margin-top: -10%;
    }
    .dateContainer{
      margin-bottom: 17px;
    }
  }
}
@media screen and (max-width:767px) {
  .ScheduleDateList{
    margin-top: 13%;
    .title{
        h1{
          font-size: 26px !important;;

        }
    }
  }
}

@media screen and (max-width:575px) {
  .ScheduleDateList{
    .dateContainer{
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .dateContainerLast{
      margin-top: 15px;
      margin-bottom: 15px;
     } 
  }
}
@media screen and (max-width:480px) {
  .ScheduleDateList {

    .title{
        text-align: left !important;
        h1{
          font-size: 22px !important;
          margin-top: 8%;

        }
    }
    .watchLivebtn{
      margin-top: -6%;
        .watchbtn{
          font-size: 17px !important;
          width: 147px !important;
          height: 48px !important;
          position: relative;
          top: -49%;

           a{
           
           
           }
        }
    
    }
   .dateContainer{
    margin-top: 16px;
    margin-bottom: 17px;
    .day{
    }
    .date{
    }

   } 

    .dateContainer:hover {
    }
    .dateContainer:hover {
      .day{
      }
    }

   .dateContainerLast{
   } 
   .day{
   
   }
   .date{
   
   }
}
}