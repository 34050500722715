@import '../../styles/animation.scss';
.EpisodeThumb {
    padding-bottom: 60px;
    
    .episodeRow{
        margin-left: -8px;
        margin-right: -8px;
        
    }
    .EpisodeShimmerCol{
        padding-left: 8px;
        padding-right: 8px;
    }
    .episodeBox {
        padding-left: 8px;
        padding-right: 8px;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        
        .episodeGridBox{
            position: relative;
            display: flex;
            flex-direction: column;
            margin-bottom: 8%;
            border-radius: 10px;
            -webkit-transition: all .4s ease-in-out;
            -moz-transition: all .4s ease-in-out;
            -ms-transition: all .4s ease-in-out;
            -o-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            &:hover {
                -webkit-animation-name: hvr-wobble-vertical;
                animation-name: hvr-wobble-vertical;
                -webkit-animation-duration: 1s;
                animation-duration: 1s;
                -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
                -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
            }
        }

        img {
            width: 100%;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            height: 285px;
            object-fit: cover;
            cursor: pointer;
            -webkit-transition: 0.4s ease;
            transition: 0.4s ease;
            -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
            -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
            -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
        }
        .content {
            background-color: #ffffff;
            
            box-shadow: 0 14px 44px 0 rgba(0, 0, 0, 0.09);
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 5% 15px;
            position:relative;

            .playButton {
                background-color: #320150;
                position: absolute;
                top: -28px;
                right: 15px;
                width: 67px;
                height: 67px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                img {
                    // width: 28px;
                    height: 26px;
                    margin-left: 5px;
                    // margin-top: 3px;
                    object-fit: contain;
                    border-radius: 0;
                    width: 50px;
                }
            }

            .padding {
                padding-top: 0;
                padding-bottom: 1%;
            }
            .session {
                padding-right: 15px;
                padding-left: 15px;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.71;
                letter-spacing: 1.2px;
                text-align: left;
                color: #a7a4a7;
                font-style: normal;
                .sessionWrap{
                    // 
                    max-width:287px;
                    width: 100%;
                }
                em,span{
                    display: inline-block;
                    font-style: normal;
                    vertical-align: top;
                }
                em{
                    max-width: 200px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    padding-right: 5px;
                    flex: 1;
                }
                span{
                    // max-width: 70px;
                    // width:100%;
                    // padding-left: 5px;
                    // text-overflow: ellipsis;
                    // white-space: nowrap;
                    // overflow: hidden;
                }
            }
            .title {
                // font-family: Nexa;
                font-size: 20px;
                font-weight: 800;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: #000000;
                padding-top: 18px;
                padding-bottom: 5px;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                 overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                min-height:80px;
                // max-width: 240px;
                width: 100%;

            }
            .timeContainer {
                margin-top: 10px;
                padding: 0 15px 0 13px ;
                
                .time {
                    border-radius: 4px;
                    background-color: #ededed;
                    font-size: 16px;
                    font-weight: 600;
                    width: 60px;
                    height: 28px;
                    padding-top: 3px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: center;
                    color: #000000;
                    margin-top: 8px;
                }
            }
        }
        .episodeImage {
            overflow: hidden;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            &:hover img {
                -webkit-transform: scale(1.08);
                transform: scale(1.08);
            }
        }
        &:hover{
            .playButton {
                -webkit-animation-name: buzz-out;
                animation-name: buzz-out;
                -webkit-animation-duration: .7s;
                animation-duration: .7s;
                -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
                -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1
            }
        }
  
    }
}

@media screen and (max-width:1199px) {
    .EpisodeThumb {
        .episodeBox {
             .content {
                 .playButton{
                    top: -47px;
                    width: 60px;
                    height: 60px;
                 }
                  .timeContainer {
                    padding-left:0;
                    
                }
             }
        }
    }
}

@media screen and (max-width:1080px) {}

@media screen and (max-width:991px) {
    .EpisodeThumb {
        .episodeBox {
            
            .content {
                .playButton {
                    img {}
                }
                .padding {}
                .session {
                    .sessionWrap{

                    }
                    em{
                        max-width: 220px;
                    }
                }
                .title {
                    font-size: 20px;
                }
                .timeContainer {
                    
                    .time {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:767px) {
    .EpisodeThumb {
        padding-bottom: 50px;
        .episodeBox {
            
            // img {
            //     width: 100%;
            // }
            .content {
                .playButton {
                    top:-23px;
                    img {}
                }
                .padding {}
                .session {
                    min-height:auto;
                    .sessionWrap{
                        max-width: 400px;
                    }
                    em{
                        max-width: 300px;
                    }
                }
                .title {
                    min-height: auto;
                }
                .timeContainer {
                    display: flex;
                    justify-content: flex-end;
                    .time {}
                }
            }
        }
    }
}

@media screen and (max-width:575px) {}

@media screen and (max-width:480px) {
    .EpisodeThumb {
        padding-bottom: 30px;
        .episodeBox {
            // img {
            //     width: 100%;
            // }
            .episodeGridBox{
                margin-bottom:20px;
            }
            .content {
                .playButton {
                    width: 45px;
                    height: 45px;
                    img {
                        width: 18px;
                    }
                }
                .padding {}
                .session {
                    .sessionWrap{
                        max-width: 280px;
                    }
                    em{
                        max-width: 215px;
                    }
                }
                .title {
                    font-size: 15px;
                   
                }
                .timeContainer {
                    .time {
                        margin-top: 1px;
                    }
                }
            }
        }
    }
}

@media screen and (max-height:600px) and (max-width:767px) {}

@media screen and (max-height:600px) and (max-width:575px) {}

@media screen and (max-height:400px) and (max-width:991px) {}

@media screen and (max-height:360px) and (max-width:991px) {}