@import url("https://fonts.googleapis.com/css2?family=Carattere&family=Inter:wght@300;400;500;600;700&family=Lato:wght@300;400;700;900&display=swap");

/****variables*****/
$primary-color: #6a292c !default;
$secondary-color: #c2815b !default;
$quinary-color: #db0800 !default;
$font-Lato: "Lato", sans-serif;
$font-Inter: "Inter", sans-serif;
$font-Carattere: "Carattere", cursive;

$primary: #6a292c;
$secondary: #c2815b;
$quinary: #db0800;

$body-bg: #151515;
$black: #000;
$white: #fff;
$bg-dark: #1a1a1a;
$text-grey: #7b7b7b;
$light-grey: #f8f8f8;
$bg-grey: #f0f0f0;
$bg-cream: #faf7ed;

$border-input: #e5e5e5;
$border-line: #e8e8e8;
$border-brown: #d5a483;
$border-orange: #ff9831;

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-black: 900;
/****mixins*****/

@mixin px-rem($pxValue, $property: "font-size", $standard-size: 18) {
  #{$property}: $pxValue/$standard-size + rem;
}

$base-size: 18;

@function size($target, $context: $base-size) {
  @return ($target / $context) * 1rem;
}

@mixin min-max($resMin, $resMax) {
  @media (min-width: $resMin+px) and (max-width: $resMax+px) {
    @content;
  }
}

@mixin max($res) {
  @media (max-width: $res+px) {
    @content;
  }
}

@mixin min($res) {
  @media (min-width: $res+px) {
    @content;
  }
}
@mixin transition($ele, $ease, $time) {
  -webkit-transition: $ele $time $ease;
  -o-transition: $ele $time $ease;
  transition: $ele $time $ease;
}
@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}
@mixin shineShimmer($time) {
  background: rgb(186, 186, 186);
  background: linear-gradient(
    90deg,
    rgba(186, 186, 186, 1) 0%,
    rgba(237, 237, 237, 1) 44%,
    rgba(255, 255, 255, 1) 50%,
    rgba(235, 235, 235, 1) 55%,
    rgba(186, 186, 186, 1) 100%
  );
  background-size: 900%;
  background-position: 100% 0%;
  animation-fill-mode: forwards;
  animation: bgShine $time infinite ease-in-out;
}
@keyframes bgShine {
  0% {
    background-position: 100% 0%;
  }
  99% {
    background-position: -10% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}
.secAnim {
    opacity: 0;
    visibility: hidden;
    transition: all ease 1s;
    -o-transition: all ease 1s;
    -webkit-transition: all ease 1s;
}
@-webkit-keyframes growBar1 {
	0% {
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
		transform: scaleX(0.1);
	}
	36.6% {
		-webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
		animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
		transform: scaleX(0.1);
	}
	69.15% {
		-webkit-animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
		animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
		transform: scaleX(0.83);
	}
	100% {
		transform: scaleX(0.1);
	}
}
@keyframes growBar1 {
	0% {
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
		transform: scaleX(0.1);
	}
	36.6% {
		-webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
		animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
		transform: scaleX(0.1);
	}
	69.15% {
		-webkit-animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
		animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
		transform: scaleX(0.83);
	}
	100% {
		transform: scaleX(0.1);
	}
}
@-webkit-keyframes moveBar1 {
	0% {
		left: -105.16667%;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}
	20% {
		left: -105.16667%;
		-webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
		animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
	}
	69.15% {
		left: 21.5%;
		-webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
		animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
	}
	100% {
		left: 95.44444%;
	}
}
@keyframes moveBar1 {
	0% {
		left: -105.16667%;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}
	20% {
		left: -105.16667%;
		-webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
		animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
	}
	69.15% {
		left: 21.5%;
		-webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
		animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
	}
	100% {
		left: 95.44444%;
	}
}
@-webkit-keyframes growBar2 {
	0% {
		-webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
		animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
		transform: scaleX(0.1);
	}
	19.15% {
		-webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
		animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
		transform: scaleX(0.57);
	}
	44.15% {
		-webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
		animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
		transform: scaleX(0.91);
	}
	100% {
		transform: scaleX(0.1);
	}
}
@keyframes growBar2 {
	0% {
		-webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
		animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
		transform: scaleX(0.1);
	}
	19.15% {
		-webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
		animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
		transform: scaleX(0.57);
	}
	44.15% {
		-webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
		animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
		transform: scaleX(0.91);
	}
	100% {
		transform: scaleX(0.1);
	}
}
@-webkit-keyframes moveBar2 {
	0% {
		left: -54.88889%;
		-webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
		animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
	}
	25% {
		left: -17.25%;
		-webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
		animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
	}
	48.35% {
		left: 29.5%;
		-webkit-animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
		animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
	}
	100% {
		left: 117.38889%;
	}
}
@keyframes moveBar2 {
	0% {
		left: -54.88889%;
		-webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
		animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
	}
	25% {
		left: -17.25%;
		-webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
		animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
	}
	48.35% {
		left: 29.5%;
		-webkit-animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
		animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
	}
	100% {
		left: 117.38889%;
	}
}@-webkit-keyframes growBar1 {
	0% {
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
		transform: scaleX(0.1);
	}
	36.6% {
		-webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
		animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
		transform: scaleX(0.1);
	}
	69.15% {
		-webkit-animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
		animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
		transform: scaleX(0.83);
	}
	100% {
		transform: scaleX(0.1);
	}
}
@keyframes growBar1 {
	0% {
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
		transform: scaleX(0.1);
	}
	36.6% {
		-webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
		animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
		transform: scaleX(0.1);
	}
	69.15% {
		-webkit-animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
		animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
		transform: scaleX(0.83);
	}
	100% {
		transform: scaleX(0.1);
	}
}
@-webkit-keyframes moveBar1 {
	0% {
		left: -105.16667%;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}
	20% {
		left: -105.16667%;
		-webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
		animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
	}
	69.15% {
		left: 21.5%;
		-webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
		animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
	}
	100% {
		left: 95.44444%;
	}
}
@keyframes moveBar1 {
	0% {
		left: -105.16667%;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}
	20% {
		left: -105.16667%;
		-webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
		animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
	}
	69.15% {
		left: 21.5%;
		-webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
		animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
	}
	100% {
		left: 95.44444%;
	}
}
@-webkit-keyframes growBar2 {
	0% {
		-webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
		animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
		transform: scaleX(0.1);
	}
	19.15% {
		-webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
		animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
		transform: scaleX(0.57);
	}
	44.15% {
		-webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
		animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
		transform: scaleX(0.91);
	}
	100% {
		transform: scaleX(0.1);
	}
}
@keyframes growBar2 {
	0% {
		-webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
		animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
		transform: scaleX(0.1);
	}
	19.15% {
		-webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
		animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
		transform: scaleX(0.57);
	}
	44.15% {
		-webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
		animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
		transform: scaleX(0.91);
	}
	100% {
		transform: scaleX(0.1);
	}
}
@-webkit-keyframes moveBar2 {
	0% {
		left: -54.88889%;
		-webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
		animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
	}
	25% {
		left: -17.25%;
		-webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
		animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
	}
	48.35% {
		left: 29.5%;
		-webkit-animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
		animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
	}
	100% {
		left: 117.38889%;
	}
}
@keyframes moveBar2 {
	0% {
		left: -54.88889%;
		-webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
		animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
	}
	25% {
		left: -17.25%;
		-webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
		animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
	}
	48.35% {
		left: 29.5%;
		-webkit-animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
		animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
	}
	100% {
		left: 117.38889%;
	}
}

