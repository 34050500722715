$backgroundColor: #f7f7f7;
$backgroundColorTwo:#e6e6e6;
$pmColor: #320150;
$pmFontcolor: #ffffff;
$fTitle: #162d3d;
$fbackgroundColor: #28013f;
$copyrightFontColor: hsl(278, 4%, 44%);
$backtotopColor: #959595;
$shimmerColor:#D7D7D7; 
$shimmerColorSec:#ebe8e8;

