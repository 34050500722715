.policyContentWrapper{
    position: relative;
    color:#626262;
    padding: 70px 0;
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
       p{
        font-size: 15px;
        line-height: 24px;
        font-weight: 500;
        color:#626262;
        margin: 0 0 25px;
    }
    ul{
        margin: 0 0 25px;
    }
    h1,h2,h3,h4,h5,h6{
        color: #000;
    }
    h1{
        font-size: 54px;
        line-height: 66px;
        font-weight: 900;
        margin-bottom: 25px;
    }
    h2{
        font-size: 30px;
        line-height: 44px;
        font-weight: 700;
        margin-bottom: 25px
    }
    h3{
        font-size: 26px;
        line-height: 32px;
        font-weight: 600;
        margin-bottom: 25px;
    }
    h4{
        font-size: 20px;
        line-height: 26px;
        font-weight: 600;
        margin-bottom: 25px;
    }
    h5{
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    h6{
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        margin-bottom: 15px;
    }
}

@media screen and (max-width:1466px) {
    .policyContentWrapper {
        padding: 50px 0;

        h1{
            font-size: 48px;
            line-height: 54px;
            margin-bottom: 20px;
        }
    }
    
}
@media screen and (max-width:1024px) {
    .policyContentWrapper {
        padding: 40px 0;
    
        h1{
            font-size: 36px;
            line-height: 44px;
            margin-bottom: 15px;
        }
        h2{
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 15px
        }
        h3{
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 15px;
        }
        h4{
            font-size: 19px;
            line-height: 24px;
            margin-bottom: 15px;
        }
        h5{
            font-size: 17px;
            line-height: 22px;
            margin-bottom: 13px;
        }
        h6{
            font-size: 15px;
            line-height: 17px;
            margin-bottom: 11px;
        }
    }
}
@media screen and (max-width:768px) {
    .policyContentWrapper {
        padding: 30px 0;
    
        h1{
            font-size: 25px;
            line-height: 30px;
            word-break: break-word;
        }
        h2{
            font-size: 20px;
            line-height: 26px;
        }
        h3{
            font-size: 18px;
            line-height: 24px;
        }
        h4{
            font-size: 17px;
            line-height: 22px;
        }
        h5{
            font-size: 15px;
            line-height: 20px;
        }
        h6{
            font-size: 13px;
            line-height: 17px;
        }
    }
}