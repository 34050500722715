.MediaPartnersWrapper{
    height: 225px;
    background: #fff;
    padding:0;
    display: flex;
    align-items:center;
    @media (max-width: 768px) {
        height: auto;
        padding: 30px 0;
    }
}
.mediapartnersCarousel{
    display: flex;
    align-items:center;
    overflow: hidden;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    .leftTextCarousel{
        flex: 0 0  206px;
        position: relative;
        @media (max-width: 1366px) {
            flex: 0 0  190px;
        }
        @media (max-width: 1199px) {
            flex: 0 0  170px;
        }
        @media (max-width: 1024px) {
            flex: 0 0  150px;
        }
        &:after{
            content: "";
            position:absolute;
            top:50%;
            right:0;
            transform:translateY(-50%);
            width: 1px;
            height: 86px;
            background: #707070;
            opacity: 0.32;
            @media (max-width: 768px) {
                display: none;
            }
        }
        .mpTitle{
            display:inline-block;
            max-width: 115px;
            position:relative;
            padding-bottom:15px;
            font-size: 25px;
            line-height: 36px;
            font-weight: 900;
            &:after{
                content: "";
                position:absolute;
                top:98%;
                left:0;
                width: 18px;
                height: 3px;
                background: #320150;
                @media (max-width: 1366px) {
                    top:95%;
                }
               
            }
        }
    }
    .rightSliderCarousel{
        flex:1;
        padding-left: 30px;
        @media (max-width: 768px) {
            padding-left: 0;
        }
        .mediapartnerSlider{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 -15px;
            @media (max-width: 768px) {
                flex-wrap: wrap;
                justify-content: center;
            }
            .mp_slide{
                padding: 0 15px;
                @media (max-width: 768px) {
                    padding: 0 15px 20px;
                }
                img{
                    max-width:110px;
                    opacity:1;
                    margin: 0 auto;
                    // @media (max-width: 1200px) {
                    //     max-width:140px;
                    // }
                }
            }
        }
    }
}