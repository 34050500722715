@import '../../styles/_colors.scss';
.footer_links {
    background-color: $pmColor;
    overflow-x: hidden;
    min-height: 410px;
    padding: 68px 0 0;
    .footer_menulink {
        min-height: 175px;
        font-size: 17px;
        font-weight: 400 !important;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.94;
        letter-spacing: normal;
        text-align: left;
        color: $pmFontcolor;
        text-decoration: none;
        h4{
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 10px;
        }
        a {
            font-size: 18px;
            font-weight: 400 !important;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.94;
            letter-spacing: normal;
            text-align: left;
            color: #bababa;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;
            
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px rgb(0 0 0 / 0%);
            -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
            &:hover {
                color: $pmFontcolor;
                -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
            }
        }
        .mbLnk{
            cursor: pointer;
        }
        .main_menu{
            position: relative;
        }
        .footer_applink {
            min-height: 54px;
            // margin-left: 5%;
            font-size: 21px;
            font-weight: 400 !important;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: left;
            color: $pmFontcolor !important;
            .apps {
                font-size: 24px;
                font-weight: 400;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #ffffff;
            }
            .downloadApps{
                img{
                    cursor: pointer;
                }
            }
            .imgMargin {
                margin-top: 13px !important;
                .imgios {
                    width: 30px;
                    height: 30px;
                    margin-left: -23%;
                }
                .imgios,.imgandroid{
                    cursor: pointer;
                }
            }
        }
    }
    .copy_right {
        min-height: 64px;
        margin: 100px 0 0;
        padding: 25px 15px 22px;
        background-color: $fbackgroundColor;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $copyrightFontColor !important;
        width: 100% !important;
       
    }
    .copy_rightClr {
        color: $copyrightFontColor !important; 
        text-decoration: none !important;
        -webkit-transition: all ease-in-out .4s;
        -moz-transition: all ease-in-out .4s;
        -o-transition: all ease-in-out .4s;
        transition: all ease-in-out .4s;
        &:hover{
            color: $pmFontcolor !important;
        }
    }
    .backtotop {
        position: absolute;
        right: 0;
        transform: rotate(-90deg) !important;
        font-size: 18px !important;
        color: $backtotopColor !important;
        margin-top: -3%;
        text-decoration: none;
        -webkit-transition: all ease-in-out .4s;
        -moz-transition: all ease-in-out .4s;
        -o-transition: all ease-in-out .4s;
        transition: all ease-in-out .4s;
        cursor: pointer;  
        @media (max-width:1366px) {
            margin-top: -2%;
            p{
                margin-bottom: 2.3rem;
                font-size: 16px;
            }

        }


        .arrow {
            border: solid 2px #ffa700;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            position: relative;
            left: 0;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -webkit-transition: all ease-in-out .4s;
            -moz-transition: all ease-in-out .4s;
            -o-transition: all ease-in-out .4s;
            transition: all ease-in-out .4s;
        }
        &:hover {
            color: $pmFontcolor !important;
            .arrow{
                left: 6px;
            }
        } 
    }
}
:global .modal-backdrop.show{
    opacity:0.5;
}

@media screen and (max-width:1199px) {}

@media screen and (max-width:1080px) {}

@media screen and (max-width:991px) {}

@media screen and (max-width:767px) {
    .footer_menulink {
        .main_menu{
            order: 1;
        }
        .mediaContainer {
            order:2;
        }
        .contactus{
            order: 3;
            margin-top: 5%;   
        }
        .backtotop{
            right: -20px;    
            margin-top: -40%;

            p{
                margin: 0;
            }
        }
        .footer_applink {
            order:4;
            margin-top: 5%;
            .appsborder{
                border: 1px solid #fff;
                align-items: center;
                padding: 10px 0;
                margin: 0;
                h5{
                    margin: 0;
                }
            }
            .appIcons{
                a{
                    display: inline-block;
                }
            }
        }
        
    }
}

@media screen and (max-width:575px) {
    .footer_links{
        .footer_menulink {
            font-size: 16px;
            a{
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width:480px) {
    .footer_links {
        height: auto;
        padding: 40px 0 0;
        .footer_menulink {
            .mediaContainer {
                // left: 40%;
                // top: -376px;
            }
            .contactus {
                margin-top: 5%;
            }
            .footer_applink {
                .appIcons{
                    .col-6{
                        padding-left: 0;
                    }
                }
                .apps {
                    font-size: 20px;
                }
            }
        }
        .copy_right {
            margin: 40px 0px 0px;
            height: 100px;
            padding: 13%;
            padding-top: 35px;
        }
        .backtotop {
            display: none !important;
            margin-top: -50%;
        }
        .arrow {
            display: none;
        }
    }
}

@media screen and (max-height:600px) and (max-width:767px) {}

@media screen and (max-height:600px) and (max-width:575px) {}

@media screen and (max-height:400px) and (max-width:991px) {}

@media screen and (max-height:360px) and (max-width:991px) {}